import * as PIXI from 'pixi.js';

import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/index.js';
import { perFrameTimer } from 'common/helpers/converters.js';

import { InterfaceBase } from '../interface-base.js';

const SOUND_DELAY = perFrameTimer(0.05);

export class Target extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.locked = true;
    this.lockingPosition = null;

    this.interactive = false;
    this.interactiveBlockades = 1;

    this.soundTimer = 0;

    this.createSprite();
    this.updatePosition();
    this.registerEventListeners();
  }

  lock() {
    this.locked = true;
    this.selectingOverlaySprite.visible = false;
  }

  unlock() {
    this.locked = false;
    this.selectingOverlaySprite.visible = true;
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('escKeyDown', this);
  };

  createSprite() {
    this.clickableSprite = new PIXI.Sprite();

    this.clickableSprite.x = 0;
    this.clickableSprite.y = 0;

    this.clickableSprite.hitArea = new PIXI.Circle(0, 0, 65);
    this.clickableSprite.addEventListener('click', withLeftClick(this.onTargetClick));
    this.clickableSprite.addEventListener('rightdown', this.mouseRightDown);
    this.clickableSprite.cursor = 'pointer';

    this.registerMapInterfaceSprite(this.clickableSprite);

    this.visibleSprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.TARGET);
    this.visibleSprite.x = 0;
    this.visibleSprite.y = 0;

    this.visibleSprite.tint = this.kingdom.getColor();
    this.visibleSprite.zIndex = Z_INDEX.TARGET;

    this.registerMapSprite(this.visibleSprite);

    this.selectingOverlaySprite = new PIXI.Container();
    this.selectingOverlaySprite.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.selectingOverlaySprite.eventMode = 'dynamic';
    this.selectingOverlaySprite.zIndex = Z_INDEX.SELECTION_OVERLAY;
    this.selectingOverlaySprite.visible = false;
    this.selectingOverlaySprite.addEventListener('click', withLeftClick(this.onOverlayClick));
    this.selectingOverlaySprite.addEventListener('rightdown', this.mouseRightDown);

    this.registerSprite(this.selectingOverlaySprite);
  }

  updatePosition() {
    const position = this.kingdom.getTarget().clone();

    this.visibleSprite.x = position.x;
    this.visibleSprite.y = position.y;

    this.clickableSprite.x = position.x;
    this.clickableSprite.y = position.y;
  }

  onEscKeyDown = () => {
    if (!this.interactive) return false;
    if (this.locked) return false;

    this.cancelChange();

    return true;
  };

  onTargetClick = () => {
    if (!this.interactive) return false;

    this.unlock();

    this.game.interfaceController.infoUnderBar.showMessage('Choose new target', 0);
  };

  onOverlayClick = () => {
    if (!this.interactive) return false;

    if (!this.lockingPosition) return false;

    this.lock();

    this.game.interfaceController.infoUnderBar.fadeOutMessage();

    this.parent.changeTarget(this.lockingPosition);
  };

  mouseRightDown = () => {
    this.game.eventsController.runEvent('redirectedMouseRightDown');
  };

  destroy() {
    super.destroy();
    this.removeMapInterfaceSprite(this.clickableSprite);
    this.removeMapSprite(this.visibleSprite);
    this.removeSprite(this.selectingOverlaySprite);
  }

  activate(delta, paused) {
    if (paused) return false;

    this.moveToMouseHex();
    this.changeCursorForOverlay();
    this.calculateSoundTimer(delta);
  }

  calculateSoundTimer(delta) {
    if (this.soundTimer <= 0) return;

    this.soundTimer -= delta;

    if (this.soundTimer > 0) return;

    this.playSound();
  }

  playSound() {
    this.game.soundsController.playSound(SOUNDS.SELECTING_FIELD, { speedRange: 0.1 });
  }

  playDelayedSound() {
    this.soundTimer = SOUND_DELAY;
  }

  moveToMouseHex() {
    if (this.locked) return false;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    if (!field) {
      this.lockingPosition = false;
      return;
    }

    const finalPosition = hexPosition.toPoint();

    if (finalPosition.sameAs(this.lockingPosition)) return false;

    this.lockingPosition = finalPosition;

    this.visibleSprite.x = finalPosition.x;
    this.visibleSprite.y = finalPosition.y;

    this.clickableSprite.x = finalPosition.x;
    this.clickableSprite.y = finalPosition.y;

    this.playDelayedSound();
  }

  changeCursorForOverlay() {
    if (this.locked) return false;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    this.selectingOverlaySprite.cursor = field ? 'pointer' : 'default';
  }

  blockInteractive() {
    this.interactiveBlockades++;
    if (this.interactiveBlockades === 1) this.setInteractive(false);
  }

  unblockInteractive() {
    this.interactiveBlockades--;
    if (this.interactiveBlockades === 0) this.setInteractive(true);
  }

  setInteractive(value) {
    this.interactive = value;
    this.clickableSprite.eventMode = value ? 'static' : 'none';
  }

  onNewTarget() {
    this.updatePosition();
    this.lockingPosition = null;
  }

  cancelChange() {
    this.lock();
    this.updatePosition();
    this.lockingPosition = null;
    this.game.interfaceController.infoUnderBar.fadeOutMessage();
  }
}
