import {
  CYCLES_PER_REDRAW,
  DRAWING_TIMER,
  HARD_CARD_LIMIT,
  SHUFFILING_TIMER,
  STARTING_ENERGY,
  STARTING_ENERGY_PRODUCTION,
  STARTING_MAX_CARDS,
} from 'common/consts/gameplay.js';
import { ENTITIES } from 'common/consts/types/entities.js';
import { Point } from 'common/data-types/point.js';
import { EntityBase } from 'common/entities/entity-base.js';

import { COLORS } from '../consts/colors.js';
import { randomize } from '../helpers/prototype-extensions.js';

import { Effects } from './kingdoms/effects.js';

export class Kingdom extends EntityBase {
  constructor(params) {
    super({ ...params, priority: 3 });

    this.color = params.color;
    this.target = params.target || new Point();

    this.deck = [];
    this.hand = [];
    this.active = [];
    this.discard = [];

    this.effects = new Effects({ kingdom: this, game: this.game, visible: params.visible });

    this.energy = params.energy || STARTING_ENERGY;
    this.energyProduction = params.energyProduction || STARTING_ENERGY_PRODUCTION;

    this.cyclesPerRedraw = CYCLES_PER_REDRAW;
    this.redrawCounter = params.redrawCounter || 0;
    this.canRedraw = params.canRedraw || false;

    this.cardsToDraw = params.cardsToDraw || 0;

    this.drawingTimer = 0;
    this.shufflingTimer = 30;

    this.ownedTiles = 0;
    this.mainBuilding = null;
    this.eliminated = false;

    this.cardsLimit = STARTING_MAX_CARDS;

    this.connected = false;

    this.playerId = params.playerId || null;

    this.difficulty = params.difficulty;
  }

  activate(delta) {
    if (this.eliminated) return false;

    this.calculateTimers(delta);
    this.calculateDrawing();
  }

  calculateTimers(delta) {
    if (this.shufflingTimer > 0) this.shufflingTimer -= delta;
    if (this.drawingTimer > 0) this.drawingTimer -= delta;
  }

  calculateDrawing() {
    if (this.drawingTimer > 0) return;
    if (this.shufflingTimer > 0) return;

    if (this.cardsToDraw === 0) return;

    if (this.advancedLogicPart) this.tryDrawCard();
  }

  onLoseTile() {
    this.addOwnedTiles(-1);
    this.onOwnedTilesChanged();
  }

  onGainTile() {
    this.addOwnedTiles(1);
    this.onOwnedTilesChanged();
  }

  getCanRedraw() {
    return this.canRedraw;
  }

  getRedrawCounter() {
    return this.redrawCounter;
  }

  getCyclesPerRedraw() {
    return this.cyclesPerRedraw;
  }

  getOwnedTiles() {
    return this.ownedTiles;
  }

  addOwnedTiles(value) {
    this.ownedTiles += value;
    this.onOwnedTilesChanged();
  }

  onOwnedTilesChanged() {}

  getColor() {
    return this.color;
  }

  getColorName() {
    return COLORS.NAMES[this.color];
  }

  setStartingTarget(point) {
    this.target = point.clone();
  }

  changeTarget(point) {
    this.target = point.clone();
    this.game.mapController.rotateBuildingsForKingdom(this);

    this.uploadChange({ target: this.target.toObject() }, true);

    this.onTargetChange();
  }

  getTarget() {
    return this.target;
  }

  startingCards() {
    return [];
  }

  createDeck() {
    this.deck = this.startingCards();
    this.onDeckInitialize();
  }

  setup() {
    this.createDeck();
    this.randomizeDeck();
  }

  startShuffiling() {
    this.shufflingTimer = SHUFFILING_TIMER;
    this.onShufflingStart();
  }

  randomizeDeck() {
    randomize(this.deck);
  }

  increaseCardsLimit(value) {
    this.cardsLimit += value;
  }

  tryRedraw() {
    if (!this.canRedraw) return false;

    this.setCanRedraw(false);

    this.redraw();

    return true;
  }

  redraw() {
    this.discardActive();
    this.discardHand();
    this.unexhaustDiscard();
    this.produceEnergy();
    this.orderDrawingToLimit();
    this.effects.triggerRedraw();
  }

  setCanRedraw(value) {
    this.canRedraw = value;

    this.uploadChange({ canRedraw: this.canRedraw });

    this.onRedrawChange();
  }

  setRedrawingCounter(value) {
    this.redrawCounter = value;
    this.onRedrawChange();
  }

  orderDrawingToLimit() {
    const quantity = Math.max(this.cardsLimit - this.hand.length, 0);
    this.orderDrawingCards(quantity);
  }

  orderDrawingCards(quantity) {
    if (this.cardsToDraw === 0) this.onDrawingStart();
    this.setCardsToDraw(quantity);
  }

  setCardsToDraw(quantity) {
    this.cardsToDraw = quantity;

    this.uploadCardsToDraw();
  }

  setCardsToDrawFromHost(quantity) {
    if (this.cardsToDraw === 0 && quantity > 0) this.onDrawingStart();
    if (this.cardsToDraw > 0 && quantity === 0) this.onDrawingEnd();
    this.cardsToDraw = quantity;
  }

  increaseCardsToDraw(quantity) {
    this.cardsToDraw += quantity;

    this.uploadCardsToDraw();
  }

  uploadCardsToDraw() {
    this.uploadChange({ cardsToDraw: this.cardsToDraw });
  }

  uploadChange(params, isPublic) {
    if (this.game.shouldSendEvent()) this.game.connectionController.onKingdomUpdate(this, params, isPublic);
  }

  tryDrawCard = () => {
    if (this.hand.length >= HARD_CARD_LIMIT) {
      this.onDrawingEnd();
      this.setCardsToDraw(0);
      this.onOverdraw();

      return;
    }

    if (this.isDeckEmpty()) {
      if (!this.isDiscardAvailable()) {
        this.onDrawingEnd();
        return this.setCardsToDraw(0);
      }

      return this.shuffleDiscardIntoDeck();
    }

    const card = this.deck.shift();
    this.hand.unshift(card);

    if (!card.isLightweight()) {
      this.increaseCardsToDraw(-1);
    }

    if (this.cardsToDraw > 0) {
      this.drawingTimer = DRAWING_TIMER;
    } else {
      this.onDrawingEnd();
    }

    this.onDrawingCard();

    this.onDeckChange();
    this.onHandChange();

    if (this.game.shouldSendEvent()) this.game.connectionController.onCardDraw(card);
  };

  drawById(cardId) {
    const index = this.deck.findIndex((card) => card.id === cardId);
    if (index < 0) return;

    const card = this.deck[index];

    this.deck.splice(index, 1);
    this.hand.unshift(card);

    this.onDeckChange();
    this.onHandChange();
  }

  discardById(cardId) {
    const card = this.getCardById(cardId);
    if (!card) return;

    card.hideSpinner();
    this.removeCardById(cardId);
    this.addToDiscard(card);
  }

  trashById(cardId) {
    const card = this.getCardById(cardId);
    if (!card) return;

    card.trash();
  }

  returnById(cardId) {
    const card = this.getCardById(cardId);
    if (!card) return;

    card.clearTemporaryActions();

    this.removeCardById(cardId);
    this.addToDeck(card);
  }

  discardFromPlay(card) {
    this.removeCard(card);
    this.addToDiscard(card);

    card.exhaust();
  }

  isDeckEmpty = () => {
    return this.deck.length === 0;
  };

  hasDiscardAnyAvailab = () => {
    return this.discard.length === 0;
  };

  isDiscardAvailable = () => {
    return this.discard.filter((card) => !card.exhausted).length;
  };

  isDiscardEmpty = () => {
    return this.discard.length === 0;
  };

  isActiveEmpty = () => {
    return this.active.length === 0;
  };

  unexhaustDiscard() {
    this.discard.forEach((card) => card.unexhaust());
  }

  discardActive() {
    const cards = this.active;
    this.active = [];

    this.discard = this.discard.concat(cards);

    cards.forEach((card) => card.clearTemporaryActions());

    this.onActiveChange();
    this.onDiscardChange();
  }

  discardHand() {
    const cards = this.hand;
    this.hand = [];

    this.discard = this.discard.concat(cards.reverse());

    if (this.game.shouldSendEvent()) this.game.connectionController.onDiscardCards(cards);

    this.onHandChange();
    this.onDiscardChange();
  }

  shuffleDiscardIntoDeck() {
    this.startShuffiling();

    const returnedCards = this.discard.filter((card) => !card.exhausted);
    const leftCards = this.discard.filter((card) => card.exhausted);

    this.deck = this.deck.concat(returnedCards);

    this.discard = leftCards;

    if (this.game.shouldSendEvent()) this.game.connectionController.onReturnCards(returnedCards);
    if (this.game.shouldSendEvent()) this.game.connectionController.onShuffiling(this);

    this.randomizeDeck();

    this.onActiveChange();
    this.onDiscardChange();
    this.onDeckChange();
  }

  addToDiscard(card) {
    this.discard.push(card);

    this.onDiscardChange();
  }

  addToActive(card) {
    this.active.unshift(card);
    this.onActiveChange();
  }

  addToHand(card) {
    this.hand.unshift(card);
    this.onHandChange();
  }

  addToDeck(card) {
    this.deck.unshift(card);
    this.onDeckChange();
  }

  onCycleEnd() {
    if (this.eliminated) return false;
    if (!this.advancedLogicPart) return;

    this.calculateRedraw();
  }

  produceEnergy() {
    this.changeEnergy(this.energyProduction);
  }

  setEnergy(energy) {
    this.energy = energy;
    this.afterEnergyChange();
  }

  changeEnergyProduction(value) {
    this.energyProduction += value;

    this.uploadChange({ energyProduction: this.energyProduction });

    this.onEnergyChange();
  }

  setEnergyProduction(value) {
    this.energyProduction = value;
    this.onEnergyChange();
  }

  calculateRedraw() {
    this.redrawCounter++;

    if (this.redrawCounter >= this.cyclesPerRedraw) {
      this.redrawCounter = 0;
      this.setCanRedraw(true);
    }

    this.uploadChange({ redrawCounter: this.redrawCounter });

    this.onRedrawChange();
  }

  changeCyclesPerRendraw(value) {
    this.cyclesPerRedraw = value;

    if (this.redrawCounter >= this.cyclesPerRedraw) {
      this.redrawCounter = 0;
      this.canRedraw = true;
    }

    this.onRedrawChange();
  }

  changeEnergy(value) {
    this.energy += value;
    this.afterEnergyChange();
  }

  afterEnergyChange() {
    this.uploadChange({ energy: this.energy });
    this.onEnergyChange();
  }

  setMainBuilding(mainBuilding) {
    this.mainBuilding = mainBuilding;
  }

  addMainFortification(fortification) {
    const mainBuilding = this.getMainBuilding();

    if (!mainBuilding) return;

    const field = mainBuilding.getField();

    field.changeFortification(fortification);
  }

  getMainBuilding() {
    return this.mainBuilding;
  }

  getEnergy() {
    return this.energy;
  }

  getEnergyProduction() {
    return this.energyProduction;
  }

  addEffect(params) {
    this.effects.add(params);
  }

  updateEffect(params) {
    this.effects.update(params);
  }

  getEffects() {
    return this.effects;
  }

  removeEffect(id) {
    this.effects.remove(id);
  }

  loseToVoid(power) {
    const ownFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this));
    ownFields.forEach((field) => field.saveDistanceFor(this.mainBuilding.position));
    const sortedFields = ownFields.sort((fieldA, fieldB) => fieldB.distance - fieldA.distance);

    const max = sortedFields.length;
    for (let i = 0; i < max && power > 0; i++) {
      const currentField = sortedFields[i];
      power = currentField.getVoidDamage(power, null);
    }
  }

  loseFarFields(numberOfFields) {
    let ownFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this));

    if (this.mainBuilding) {
      ownFields.forEach((field) => field.saveDistanceFor(this.mainBuilding.position));
      ownFields.sort((fieldA, fieldB) => fieldB.distance - fieldA.distance);
    } else {
      randomize(ownFields);
    }

    for (let i = 0; i < numberOfFields; i++) {
      const field = ownFields[i];

      if (!field) return false;

      field.getDamage(1);
    }
  }

  onDestroyMainBuilding() {
    this.mainBuilding = null;

    this.eliminate();
  }

  eliminate() {
    this.eliminated = true;

    this.game.kingdomsController.removeKingdomById(this.id);

    this.abandonAllFields();
    this.destroyBullets();
    this.destroyCards();
    this.effects.destroy();

    this.onEliminate();

    this.game.checkForEnd();
  }

  destroy() {
    super.destroy();
  }

  isEliminated() {
    return this.eliminated;
  }

  isActive() {
    return !this.eliminated;
  }

  abandonAllFields() {
    const ownFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this));
    ownFields.forEach((field) => {
      field.dropFortifications();
      field.changeOwner(null);
    });
  }

  destroyBullets() {
    this.game.entitiesController.eachEntity((entity) => {
      if (entity.type !== ENTITIES.BULLET) return;
      if (entity.owner.id !== this.id) return;

      entity.destroy();
    });
  }

  destroyCards() {
    this.deck.forEach((card) => card.destroy());
    this.deck = [];

    this.hand.forEach((card) => card.destroy());
    this.hand = [];

    this.active.forEach((card) => card.destroy());
    this.active = [];

    this.discard.forEach((card) => card.destroy());
    this.discard = [];
  }

  moveCardToActive(card) {
    this.removeCard(card);
    this.addToActive(card);
  }

  moveCardBackToHand(card) {
    this.removeCard(card);
    this.addToHand(card);
  }

  removeCard(targetCard) {
    return this.removeCardById(targetCard.id);
  }

  removeCardById(cardId) {
    const searchFunction = (card) => card.id === cardId;

    const deckIndex = this.deck.findIndex(searchFunction);
    if (deckIndex >= 0) {
      this.deck.splice(deckIndex, 1);
      this.onDeckChange();
      return true;
    }

    const handIndex = this.hand.findIndex(searchFunction);
    if (handIndex >= 0) {
      this.hand.splice(handIndex, 1);
      this.onHandChange();
      return true;
    }

    const discardIndex = this.discard.findIndex(searchFunction);
    if (discardIndex >= 0) {
      this.discard.splice(discardIndex, 1);
      this.onDiscardChange();
      return true;
    }

    const activeIndex = this.active.findIndex(searchFunction);
    if (activeIndex >= 0) {
      this.active.splice(activeIndex, 1);
      this.onActiveChange();
      return true;
    }

    return false;
  }

  discardCard(targetCard) {
    this.removeCard(targetCard);
    this.addToDiscard(targetCard);

    if (this.game.shouldSendEvent()) this.game.connectionController.onDiscardCards([targetCard]);
  }

  setPlayerId(playerId) {
    this.playerId = playerId;
  }

  getHand() {
    return this.hand;
  }

  getDiscard() {
    return this.discard;
  }

  getDrawPile() {
    return this.deck;
  }

  getDeck() {
    return [...this.hand, ...this.deck, ...this.discard, ...this.active];
  }

  getActive() {
    return this.active;
  }

  update(params) {
    const { energy, energyProduction, canRedraw, redrawCounter, target, cardsToDraw } = params;

    if (cardsToDraw !== undefined) this.setCardsToDrawFromHost(cardsToDraw);
    if (energy !== undefined) this.setEnergy(energy);
    if (energyProduction !== undefined) this.setEnergyProduction(energyProduction);
    if (canRedraw !== undefined) this.setCanRedraw(canRedraw);
    if (redrawCounter !== undefined) this.setRedrawingCounter(redrawCounter);
    if (target !== undefined) this.changeTarget(Point.fromObject(target));
  }

  setConnected(connected) {
    this.connected = connected;
  }

  isConnected() {
    return this.connected;
  }

  toSocketData() {
    const data = {
      id: this.id,
      color: this.color,
      target: { x: this.target.x, y: this.target.y },
      cards: {
        hand: this.hand.map((card) => card.toSocketData()),
        deck: this.deck.map((card) => card.toSocketData()),
        discard: this.discard.map((card) => card.toSocketData()),
      },
      energy: this.energy,
      energyProduction: this.energyProduction,
      canRedraw: this.canRedraw,
      redrawCounter: this.redrawCounter,
      effects: this.effects.toSocketData(),
      cardsToDraw: this.cardsToDraw,
    };

    if (this.playerId) {
      data.playerId = this.playerId;
    }

    return data;
  }

  cardsFromSocketData(data) {
    this.hand = data.hand.map((cardData) => this.game.cardsCreator.fromSocketData(cardData, this));
    this.deck = data.deck.map((cardData) => this.game.cardsCreator.fromSocketData(cardData, this));
    this.discard = data.discard.map((cardData) => this.game.cardsCreator.fromSocketData(cardData, this));

    this.onDeckChange();
    this.onHandChange();
    this.onDiscardChange();
  }

  searchForPlayableCard(cardId) {
    const searchFunction = (card) => card.id === cardId;

    return this.hand.find(searchFunction) || this.active.find(searchFunction);
  }

  getCardById(cardId) {
    return this.game.entitiesController.findById(cardId);
  }

  isCardInHand(cardId) {
    return this.hand.find((card) => card.id === cardId);
  }

  createCard(params) {
    return this.game.cardsCreator.createCard({ ...params, owner: this });
  }

  getShufflingTimer() {
    return this.shufflingTimer;
  }

  getPlayerId() {
    return this.playerId;
  }

  // for replace

  onHandChange() {}

  onDiscardChange() {}

  onDeckInitialize() {}

  onHandInitialize() {}

  onDeckChange() {}

  onActiveChange() {}

  onRedrawChange() {}

  onEnergyChange() {}

  onSuppliesChange() {}

  onScienceChange() {}

  onOwnedTilesChanged() {}

  onResearchEnd() {}

  onShufflingStart() {}

  onDrawingStart() {}

  onDrawingCard() {}

  onDrawingEnd() {}

  onTargetChange() {}

  onEliminate() {}
}
