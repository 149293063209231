import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';
import { BasicCannon } from './basic.js';

export class RandomCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.fireInaccuracy = 360;
    this.rotate = false;

    this.type = BUILDINGS.RANDOM_CANNON;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.RANDOM_CANNON;
  }
}
