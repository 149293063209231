import { CARD_MOVING_SPEED } from 'client/consts/interface.js';
import { CARD_HEIGHT, CARD_SCALE, CARD_WIDTH, FOCUSED_CARD_OFFSET, REAL_CARD_HEIGHT, REAL_CARD_WIDTH } from 'client/consts/layout.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { CARD_INACTIVE_TIME, CARD_IN_HAND_TIME, CARD_TRASH_TIME } from 'common/consts/gameplay.js';
import { TOOLTIP } from 'common/consts/tooltip.js';
import { IMAGES } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';
import { EntityBase } from 'common/entities/entity-base.js';
import { perFrameTimer } from 'common/helpers/index.js';

export class Card extends EntityBase {
  constructor(params) {
    super(params);

    this.data = params.data;
    this.owner = params.owner;

    this.inactiveTimer = 0;
    this.trashingTimer = 0;
    this.discardFromPlayTimer = 0;

    this.exhausted = false;

    this.spinnerActive = false;

    this.isMouseOn = false;

    this.scale = 1;

    this.interfaceContainer = params.interfaceContainer;

    this.tooltipCategory = TOOLTIP.CATEGORY.CARD;

    if (params.hideInterface) this.interfacePart = false;

    if (this.interfacePart) this.setupInterface(params);
  }

  isLightweight() {
    return this.data.lightweight;
  }

  overrideId(newId) {
    this.id = newId;
  }

  getOwner() {
    return this.owner;
  }

  getOwnerId() {
    return this.owner.id;
  }

  getData() {
    return this.data;
  }

  activate(delta) {
    this.activateInactiveTimer(delta);

    if (this.inactiveTimer <= 0) {
      this.activateDesctructionTimer(delta);
      this.activateDiscardFromPlayTimer(delta);
    }

    if (this.destroyed) return;

    if (this.interfacePart) {
      this.activateInterface(delta);
      this.rotateSpinner();
    }
  }

  activateInterface(delta) {
    this.moveToDestination(delta);
    this.decreaseOverlay(delta);
  }

  rotateSpinner() {
    if (!this.spinnerActive) return false;
    this.spinnerSprite.rotation += 0.1;
  }

  destroy() {
    super.destroy();

    if (!this.interfacePart) return;

    this.spritesContainer.destroy();
  }

  setOwner(owner) {
    this.owner = owner;
  }

  exhaust() {
    this.exhausted = true;

    if (this.interfacePart) this.exhaustedOverlaySprite.visible = true;
  }

  unexhaust() {
    if (!this.exhausted) return;

    this.exhausted = false;

    if (this.interfacePart) this.exhaustedOverlaySprite.visible = false;
  }

  clearTemporaryActions() {
    this.inactiveTimer = 0;
    this.discardFromPlayTimer = 0;
  }

  trash() {
    if (this.interfacePart) this.setInteractive(false);

    if (this.serverPart) return this.trashInstant();

    this.trashingTimer = CARD_TRASH_TIME;
  }

  trashInstant() {
    this.owner.removeCard(this);
    this.destroy();

    if (this.game.shouldSendEvent()) this.game.connectionController.onTrashCard(this);
  }

  activateDesctructionTimer(delta) {
    if (this.trashingTimer <= 0) return false;

    this.trashingTimer -= delta;

    if (this.interfacePart) this.updateCardScale();

    if (this.trashingTimer > 0) return;

    this.trashInstant();
  }

  activateDiscardFromPlayTimer(delta) {
    if (this.discardFromPlayTimer <= 0) return false;

    this.discardFromPlayTimer -= delta;

    if (this.discardFromPlayTimer > 0) return;

    this.owner.discardFromPlay(this);
  }

  startDiscardFromPlayTimer() {
    this.discardFromPlayTimer = CARD_IN_HAND_TIME;
  }

  updateCardScale() {
    this.spritesContainer.scale.set(Math.max(this.trashingTimer / CARD_TRASH_TIME, 0));
  }

  activateInactiveTimer(delta) {
    if (this.inactiveTimer <= 0) return false;

    this.inactiveTimer -= delta;
  }

  makeInactive() {
    this.inactiveTimer = CARD_INACTIVE_TIME;
  }

  getEnergyCost() {
    return this.data.energyCost || 0;
  }

  increaseStat(name, value) {
    if (this.haveStat(name)) {
      this.data.stats[name] += value;

      if (this.game.shouldSendEvent()) this.game.connectionController.onCardUpdate(this);
      if (this.interfacePart) this.onUpdateStats();
    }
  }

  overrideStats(stats) {
    this.data.stats = stats;
    if (this.interfacePart) this.onUpdateStats();
  }

  changeEnergyCost(value) {
    this.data.energyCost = Math.max(this.getEnergyCost() + value, 0);

    if (this.game.shouldSendEvent()) this.game.connectionController.onCardUpdate(this);
    if (this.interfacePart) this.onUpdateEnergyCost();
  }

  overrideEnergyCost(value) {
    this.data.energyCost = value;
    if (this.interfacePart) this.onUpdateEnergyCost();
  }

  haveStat(name) {
    return this.data.stats && this.data.stats[name] !== undefined;
  }

  setupInterface(params = {}) {
    const { zIndex, position } = params;

    this.position = position || new Point(window.innerWidth / 2, window.innerHeight / 2);
    this.destination = this.position.clone();
    this.speed = new Point();

    this.maxSpeed = CARD_MOVING_SPEED;

    this.inFocus = false;

    this.interactive = false;

    this.overlayDecrease = null;

    this.destinationReached = false;

    this.createSprites();
  }

  setSpeed(value) {
    this.maxSpeed = value;
  }

  resetSpeed() {
    this.maxSpeed = CARD_MOVING_SPEED;
  }

  createSprites() {
    const { title, cost, image, color } = this.data;

    const TO_TOP = (-REAL_CARD_HEIGHT / 2) * CARD_SCALE;
    const TO_BOTTOM = (REAL_CARD_HEIGHT / 2) * CARD_SCALE;
    const TO_RIGHT = (REAL_CARD_WIDTH / 2) * CARD_SCALE;

    const SMALL_MARGIN = 10 * CARD_SCALE;

    const BORDER = 4 * CARD_SCALE;
    const TITLE = 50 * CARD_SCALE - BORDER;
    const IMAGE = 250 * CARD_SCALE;
    const ICON = 50 * CARD_SCALE;

    this.spritesContainer = this.game.texturesManager.createContainer();

    this.focusCotainer = this.game.texturesManager.createContainer();
    this.frontContainer = this.game.texturesManager.createContainer();
    this.backContainer = this.game.texturesManager.createContainer();

    this.selectionSprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.SELECTION);
    this.selectionSprite.x = 0;
    this.selectionSprite.y = 0;
    this.selectionSprite.visible = false;

    this.backgroundSprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.BACKGROUND);
    this.backgroundSprite.x = 0;
    this.backgroundSprite.y = 0;
    this.backgroundSprite.scale.set(CARD_SCALE);
    if (color) this.backgroundSprite.tint = color;

    this.imageBackgroundSprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.IMAGE_BACKGROUND);
    this.imageBackgroundSprite.x = 0;
    this.imageBackgroundSprite.y = TO_TOP + BORDER + TITLE + IMAGE / 2;
    this.imageBackgroundSprite.scale.set(CARD_SCALE);

    this.nameText = this.game.texturesManager.createText(title, {
      fontSize: 32 * CARD_SCALE,
      wordWrap: true,
      wordWrapWidth: REAL_CARD_WIDTH - BORDER * 2 - SMALL_MARGIN * 2,
    });

    this.nameText.x = 0;
    this.nameText.y = TO_TOP + BORDER + TITLE / 2;

    this.nameText.anchor.set(0.5);

    this.costText = this.game.texturesManager.createText(cost / 100, { fontSize: 40 * CARD_SCALE });
    this.costText.x = TO_RIGHT - BORDER - ICON - SMALL_MARGIN;
    this.costText.y = TO_BOTTOM - BORDER - SMALL_MARGIN;
    this.costText.anchor.set(1, 1);
    this.costText.visible = cost;

    this.costIconSprite = this.game.texturesManager.createSprite(IMAGES.ICONS.ENERGY);
    this.costIconSprite.x = TO_RIGHT - BORDER - SMALL_MARGIN;
    this.costIconSprite.y = TO_BOTTOM - BORDER - SMALL_MARGIN;
    this.costIconSprite.anchor.set(1, 1);
    this.costIconSprite.scale.set(0.5 * CARD_SCALE);
    this.costIconSprite.visible = cost;

    this.imageSprite = this.game.texturesManager.createStandardSprite(image);
    this.imageSprite.x = 0;
    this.imageSprite.y = TO_TOP + BORDER + TITLE + IMAGE / 2;
    this.imageSprite.scale.set(CARD_SCALE);

    const descriptionWidth = CARD_WIDTH - BORDER * 2 - SMALL_MARGIN * 2;
    this.descriptionText = this.game.texturesManager.createHTMLText(this.generateDescription(this.data), {
      wordWrap: true,
      wordWrapWidth: descriptionWidth / CARD_SCALE,
      fontSize: 32,
    });

    this.descriptionText.x = -descriptionWidth / 2;
    this.descriptionText.y = TO_TOP + TITLE + IMAGE + SMALL_MARGIN;
    this.descriptionText.scale.set(CARD_SCALE);

    this.overlaySprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.OVERLAY);
    this.overlaySprite.x = 0;
    this.overlaySprite.y = 0;
    this.overlaySprite.visible = false;
    this.overlaySprite.scale.set(CARD_SCALE);

    this.exhaustedOverlaySprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.OVERLAY);
    this.exhaustedOverlaySprite.x = 0;
    this.exhaustedOverlaySprite.y = 0;
    this.exhaustedOverlaySprite.visible = false;
    this.exhaustedOverlaySprite.scale.set(CARD_SCALE);
    this.exhaustedOverlaySprite.alpha = 0.5;
    this.exhaustedOverlaySprite.tint = '#000000';

    this.spinnerSprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.SPINNER);
    this.spinnerSprite.x = 0;
    this.spinnerSprite.y = 0;
    this.spinnerSprite.visible = false;
    this.spinnerSprite.alpha = 0.8;
    this.spinnerSprite.scale.set(CARD_SCALE);

    this.activeArea = this.game.texturesManager.createEmptySprite();
    this.activeArea.anchor.set(0.5, 1);
    this.activeArea.x = 0;
    this.activeArea.y = CARD_HEIGHT / 2;
    this.activeArea.height = CARD_HEIGHT + FOCUSED_CARD_OFFSET;
    this.activeArea.width = CARD_WIDTH;

    this.frontContainer.addChild(this.selectionSprite);
    this.frontContainer.addChild(this.backgroundSprite);
    this.frontContainer.addChild(this.imageBackgroundSprite);
    this.frontContainer.addChild(this.nameText);

    this.frontContainer.addChild(this.imageSprite);

    this.frontContainer.addChild(this.costText);
    this.frontContainer.addChild(this.costIconSprite);

    this.frontContainer.addChild(this.descriptionText);

    this.frontContainer.addChild(this.overlaySprite);
    this.frontContainer.addChild(this.exhaustedOverlaySprite);
    this.frontContainer.addChild(this.spinnerSprite);

    this.frontContainer.visible = false;

    this.backSprite = this.game.texturesManager.createStandardSprite(IMAGES.CARDS.BACK);
    this.backSprite.x = 0;
    this.backSprite.y = 0;
    this.backSprite.scale.set(CARD_SCALE);

    this.backContainer.addChild(this.backSprite);

    this.backContainer.visible = false;

    this.focusCotainer.addChild(this.frontContainer);
    this.focusCotainer.addChild(this.backContainer);
    this.spritesContainer.addChild(this.focusCotainer);
    this.spritesContainer.addChild(this.activeArea);

    this.spritesContainer.addEventListener('click', withLeftClick(this.onClick));
    this.spritesContainer.addEventListener('mouseover', this.onMouseEnter);
    this.spritesContainer.addEventListener('mouseout', this.onMouseLeave);

    this.spritesContainer.interactive = true;
    this.spritesContainer.cursor = 'pointer';

    if (this.interfaceContainer) {
      this.interfaceContainer.addChild(this.spritesContainer);
    } else {
      this.registerSprite(this.spritesContainer);
    }

    this.moveInterfaceToPosition();
  }

  blink() {
    this.showOverlay(0.75, perFrameTimer(1));
  }

  showBack() {
    this.backContainer.visible = true;
    this.frontContainer.visible = false;
  }

  showFront() {
    this.backContainer.visible = false;
    this.frontContainer.visible = true;
  }

  showOverlay(alpha, time, color) {
    this.overlaySprite.visible = true;
    this.overlaySprite.alpha = alpha;

    if (color) this.overlaySprite.tint = color;
    if (time) this.overlayDecrease = alpha / time;
  }

  showSelection() {
    this.selectionSprite.visible = true;
  }

  hideSelection() {
    this.selectionSprite.visible = false;
  }

  hideOverlay() {
    this.overlaySprite.visible = false;
    this.overlayDecrease = null;
  }

  showSpinner() {
    this.spinnerActive = true;
    this.spinnerSprite.visible = true;
  }

  hideSpinner() {
    this.spinnerActive = false;
    this.spinnerSprite.visible = false;
  }

  onUpdateStats() {
    this.updateSprites();
    this.blink();
  }

  onUpdateEnergyCost() {
    this.updateEnergySprite();
    this.blink();
  }

  updateSprites() {
    this.descriptionText.text = this.generateDescription(this.data);
  }

  updateEnergySprite() {
    const { energyCost } = this.data;

    this.costText.visible = energyCost;
    this.costIconSprite.visible = energyCost;

    if (energyCost) this.costText.text = energyCost / 100;
  }

  generateDescription(data) {
    const { description, stats = {} } = data;

    if (!stats) return description;

    let result = description;

    if (stats.energy) {
      result = result.replaceAll('ENERGY', stats.energy / 100);
    }

    if (stats.drain) {
      result = result.replaceAll('DRAIN', stats.drain / 100);
    }

    if (stats.energyProduction) {
      result = result.replaceAll('ENERGY_PRODUCTION', stats.energyProduction / 100);
    }

    for (let key in stats) {
      const formatedKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();
      const regexp = `(\\W)(${formatedKey})(\\W)`;
      result = result.replaceAll(new RegExp(regexp, 'g'), `$1${stats[key]}$3`);
    }

    return result;
  }

  onClick = () => {
    if (!this.interactive) return false;

    this.container.onCardClick && this.container.onCardClick(this);
  };

  onMouseEnter = () => {
    this.game.eventsController.runEvent('tooltipableMouseEnter', { source: this });

    if (!this.interactive) return false;

    this.isMouseOn = true;

    this.container.onCardMouseEnter && this.container.onCardMouseEnter(this);

    this.setInFocus(true);
  };

  onMouseLeave = () => {
    this.game.eventsController.runEvent('tooltipableMouseLeave', { source: this });

    this.isMouseOn = false;

    if (!this.interactive) return false;

    if (this.container.onCardMouseLeave) this.container.onCardMouseLeave(this);

    this.setInFocus(false);
  };

  onDestinationReach() {
    if (this.container && this.container.onCardDestinyReach) this.container.onCardDestinyReach(this);
  }

  setDestination(point) {
    this.destination = point;
  }

  getPosition() {
    return this.position.clone();
  }

  setPosition(point) {
    this.position = point;
    this.destination = point.clone();

    this.moveInterfaceToPosition();
  }

  moveInterfaceToPosition() {
    this.spritesContainer.x = this.position.x;
    this.spritesContainer.y = this.position.y;
  }

  setContainer(container) {
    this.container = container;
  }

  setZIndex = (zIndex) => {
    this.spritesContainer.zIndex = zIndex;
  };

  setInteractive(value) {
    this.interactive = value;
    this.spritesContainer.cursor = value ? 'pointer' : 'default';

    if (!value) this.setInFocus(false);

    if (value && this.isMouseOn) this.onMouseEnter();
  }

  setDisabled(value) {
    this.setInteractive(!value);

    if (value) {
      this.showOverlay(0.25, 0, 0x000001);
    } else {
      this.hideOverlay();
    }
  }

  moveToDestination(delta) {
    const targetPoint = this.getTargetPoint();
    const difference = targetPoint.subtract(this.position);

    if (!this.destinationReached && difference.isZero()) {
      this.destinationReached = true;
      this.onDestinationReach();
    } else if (!difference.isZero()) {
      this.destinationReached = false;
    }

    this.speed = difference.scaleSquared(Math.min(this.maxSpeed * delta, difference.length()));

    this.position.x += this.speed.x;
    this.position.y += this.speed.y;

    this.moveInterfaceToPosition();
  }

  isOnDestiny() {
    return this.getTargetPoint().subtract(this.position).isZero();
  }

  decreaseOverlay(delta) {
    if (this.overlayDecrease === null) return false;

    const decrease = this.overlayDecrease * delta;

    this.overlaySprite.alpha -= decrease;

    if (this.overlaySprite.alpha <= 0) {
      this.hideOverlay();
    }
  }

  getTargetPoint() {
    return this.destination.clone();
  }

  setInFocus(value) {
    if (value === this.inFocus) return;

    this.inFocus = value;
    this.focusCotainer.y = this.inFocus ? -FOCUSED_CARD_OFFSET : 0;
  }

  toSocketData() {
    return { id: this.id, type: this.data.type, stats: this.data.stats, energyCost: this.data.energyCost, hostId: this.owner.id };
  }

  getTooltipTypes() {
    return this.data.tooltipTypes || [];
  }

  getTooltipCategory() {
    return this.tooltipCategory;
  }

  getBounds() {
    const bounds = this.spritesContainer.getBounds();
    bounds.height = CARD_HEIGHT + FOCUSED_CARD_OFFSET;

    return bounds;
  }
}
