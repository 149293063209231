import { InterfaceBase } from '../interface-base.js';

const TEXT_SIZE = 15;
const PADDING = 5;
const BORDER_WIDTH = 3;
const BORDER_RADIUS = 3;

export class TextBox extends InterfaceBase {
  constructor(params) {
    super(params);

    this.width = 300;
    this.height = 0;
    this.text = params.text || '';
    this.zIndex = params.zIndex || 1;

    this.setupSprites();
    this.setParentContainer(params.parentContainer);
  }

  setupSprites() {
    this.createContainer();
    this.sprites.container.visible = false;

    this.createText();
    this.createBox();

    this.saveHeight();
  }

  createText() {
    const textWidth = this.width - 2 * PADDING - 2 * BORDER_WIDTH;

    this.sprites.text = this.game.texturesManager.createHTMLText(this.text, {
      fontSize: TEXT_SIZE,
      wordWrap: true,
      wordWrapWidth: textWidth,
    });

    this.sprites.text.zIndex = 1;

    this.sprites.text.x = BORDER_WIDTH + PADDING;
    this.sprites.text.y = BORDER_WIDTH + PADDING;

    this.sprites.container.addChild(this.sprites.text);
  }

  getTextHeight() {
    return this.sprites.text.height;
  }

  createBox() {
    this.sprites.box = this.game.texturesManager.createEmptySprite();
    this.sprites.container.addChild(this.sprites.box);
  }

  saveHeight() {
    this.height = this.getContainerHeight();
  }

  setVisible(visible) {
    this.sprites.container.visible = visible;
  }

  updateBoxSprite() {
    this.sprites.box.texture = this.game.texturesManager.getBoxTexture({
      width: this.width,
      height: this.getTextHeight() + BORDER_WIDTH * 2 + PADDING * 2,
      color: 0xaaaaaa,
      borderColor: 0x000000,
      borderWidth: BORDER_WIDTH,
      borderRadius: BORDER_RADIUS,
    });
  }

  updateText(text) {
    this.text = text;
    this.sprites.text.text = text;

    this.updateBoxSprite();
    this.saveHeight();
  }
}
