import * as PIXI from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';
import { InterfaceBase } from 'client/interface/interface-base.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { CARDS, EFFECTS } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';

const CURRENT_CARD = CARDS.FORTIFICATE_AROUND_MAIN || CARDS.JUNK;
const CURRENT_EFFECT = EFFECTS.VOID;

export class CheatsMenu extends InterfaceBase {
  constructor(params) {
    super(params);

    this.createSprite();
    this.setPosition();
  }

  setPosition() {
    this.position = new Point(window.innerWidth - 100, 10);

    this.spriteContainer.x = this.position.x;
    this.spriteContainer.y = this.position.y;
  }

  createSprite() {
    this.spriteContainer = new PIXI.Container();
    this.spriteContainer.zIndex = Z_INDEX.CHEAT_MENU;

    let yOffset = 0;
    const DISTANCE = 30;

    this.addEnergy = this.game.texturesManager.createOutlinedText('Add 100 energy');
    this.addEnergy.x = 0;
    this.addEnergy.y = 0;
    this.addEnergy.anchor.set(0.5, 0);
    this.addEnergy.addEventListener('click', withLeftClick(this.onAddEnergyClick));
    this.addEnergy.interactive = true;
    this.addEnergy.cursor = 'pointer';

    yOffset++;

    this.endCycle = this.game.texturesManager.createOutlinedText('End cycle');
    this.endCycle.x = 0;
    this.endCycle.y = yOffset * DISTANCE;
    this.endCycle.anchor.set(0.5, 0);
    this.endCycle.addEventListener('click', withLeftClick(this.onEndCycleClick));
    this.endCycle.interactive = true;
    this.endCycle.cursor = 'pointer';
    yOffset++;

    this.redraw = this.game.texturesManager.createOutlinedText('Redraw');
    this.redraw.x = 0;
    this.redraw.y = yOffset * DISTANCE;
    this.redraw.anchor.set(0.5, 0);
    this.redraw.addEventListener('click', withLeftClick(this.onRedrawClick));
    this.redraw.interactive = true;
    this.redraw.cursor = 'pointer';
    yOffset++;

    this.draw50 = this.game.texturesManager.createOutlinedText('Draw 50');
    this.draw50.x = 0;
    this.draw50.y = yOffset * DISTANCE;
    this.draw50.anchor.set(0.5, 0);
    this.draw50.addEventListener('click', withLeftClick(this.onDraw50));
    this.draw50.interactive = true;
    this.draw50.cursor = 'pointer';
    yOffset++;

    this.addEffect = this.game.texturesManager.createOutlinedText('Add effect');
    this.addEffect.x = 0;
    this.addEffect.y = yOffset * DISTANCE;
    this.addEffect.anchor.set(0.5, 0);
    this.addEffect.addEventListener('click', withLeftClick(this.onAddEffect));
    this.addEffect.interactive = true;
    this.addEffect.cursor = 'pointer';
    yOffset++;

    this.addCard = this.game.texturesManager.createOutlinedText('Add card');
    this.addCard.x = 0;
    this.addCard.y = yOffset * DISTANCE;
    this.addCard.anchor.set(0.5, 0);
    this.addCard.addEventListener('click', withLeftClick(this.onAddCardClick));
    this.addCard.interactive = true;
    this.addCard.cursor = 'pointer';

    this.spriteContainer.addChild(this.addEnergy);
    this.spriteContainer.addChild(this.endCycle);
    this.spriteContainer.addChild(this.redraw);
    this.spriteContainer.addChild(this.draw50);
    this.spriteContainer.addChild(this.addEffect);
    this.spriteContainer.addChild(this.addCard);

    this.spriteContainer.visible = false;

    this.registerSprite(this.spriteContainer);
  }

  onAddEnergyClick = () => {
    this.game.kingdomsController.getMainKingdom().changeEnergy(10000);
  };

  onEndCycleClick = () => {
    this.game.onCycleEnd();
  };

  onRedrawClick = () => {
    this.game.kingdomsController.getMainKingdom().redraw();
  };

  onDraw50 = () => {
    this.game.kingdomsController.getMainKingdom().orderDrawingCards(50);
  };

  onAddCardClick = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();
    const card = kingdom.createCard({ type: CURRENT_CARD });

    kingdom.addToHand(card);
  };

  onAddEffect = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();

    kingdom.addEffect({ type: CURRENT_EFFECT, power: 1 });
  };

  setVisibility(value) {
    this.spriteContainer.visible = value;
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.spriteContainer);
  }

  afterResize() {
    this.setPosition();
  }
}
