import { CARDS } from 'common/consts/types/index.js';

import { EnemyKingdom } from '../enemy.js';

export class SniperCannonKingdom extends EnemyKingdom {
  constructor(params) {
    super(params);

    if (this.difficulty < 2) this.supplies -= 3;
  }

  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.PLACE_SNIPER_CANNON }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.SNIPER_FIRE }),
      this.createCard({ type: CARDS.SNIPER_FIRE }),
      this.createCard({ type: CARDS.SNIPER_FIRE }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.FORTIFICATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.RANDOM_FORTIFICATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_MAIN }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_SNIPER_CANNON }));

    return cards;
  }

  pickNewTarget() {
    if (this.cyclesCount % 6 === 0) super.pickNewTarget();
  }
}
