import * as PIXI from 'pixi.js';

import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { BUILDINGS, ENTITIES, IMAGES } from 'common/consts/types/index.js';
import { perFrameTimer } from 'common/helpers/converters.js';

import { InterfaceBase } from '../interface-base.js';

const SOUND_DELAY = perFrameTimer(0.05);

export class FieldSelector extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.active = false;
    this.visible = false;
    this.isCorrectField = true;

    this.currentField = null;

    this.conditions = {};

    this.soundTimer = 0;

    this.createSprite();
    this.updateSpritesPosition();
  }

  createSprite() {
    this.selectionSprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.FIELD_SELECTOR);
    this.selectionSprite.x = 0;
    this.selectionSprite.y = 0;

    this.selectionSprite.zIndex = Z_INDEX.FIELD_SELECTOR;
    this.selectionSprite.visible = false;

    this.registerMapInterfaceSprite(this.selectionSprite);

    this.selectingOverlay = new PIXI.Container();
    this.selectingOverlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.selectingOverlay.eventMode = 'dynamic';
    this.selectingOverlay.zIndex = Z_INDEX.SELECTION_OVERLAY;
    this.selectingOverlay.visible = false;
    this.selectingOverlay.addEventListener('click', withLeftClick(this.onOverlayClick));
    this.selectingOverlay.addEventListener('rightdown', this.mouseRightDown);

    this.registerSprite(this.selectingOverlay);
  }

  updateSpritesPosition() {
    if (!this.currentField) return false;
    const position = this.currentField.getPosition();

    this.selectionSprite.x = position.x;
    this.selectionSprite.y = position.y;
  }

  onOverlayClick = () => {
    if (!this.active) return false;
    if (!this.isCorrectField) return false;

    this.parent.setPickedField(this.currentField);
  };

  mouseRightDown = () => {
    this.game.eventsController.runEvent('redirectedMouseRightDown');
  };

  destroy() {
    super.destroy();
    this.removeMapInterfaceSprite(this.selectionSprite);
    this.removeSprite(this.selectingOverlay);
  }

  activate(delta, paused) {
    if (paused) return false;

    this.moveToMouseHex();
    this.changeCursorForOverlay();
    this.checkSpriteChange();
    this.calculateSoundTimer(delta);
  }

  calculateSoundTimer(delta) {
    if (this.soundTimer <= 0) return;

    this.soundTimer -= delta;

    if (this.soundTimer > 0) return;

    this.playSound();
  }

  playSound() {
    this.game.soundsController.playSound(SOUNDS.SELECTING_FIELD, { speedRange: 0.1 });
  }

  playDelayedSound() {
    this.soundTimer = SOUND_DELAY;
  }

  moveToMouseHex() {
    if (!this.active) return false;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    if (!field || field.isType(ENTITIES.WALL)) {
      this.currentField = null;
      this.setVisible(false);
      return false;
    }

    if (this.currentField && this.currentField.id === field.id) return false;

    this.currentField = field;

    this.updateSpritesPosition();
    this.setVisible(true);

    this.playDelayedSound();
  }

  changeCursorForOverlay() {
    if (!this.active) return false;

    const correctSelection = this.currentField && this.isCorrectField;

    this.selectingOverlay.cursor = correctSelection ? 'pointer' : 'default';
  }

  setVisible(value) {
    if (this.visible === value) return false;

    this.visible = value;
    this.selectionSprite.visible = value;
    this.selectingOverlay.visible = value;
  }

  setCorrectSprite() {
    this.selectionSprite.tint = this.isCorrectField ? 0xffffff : 0xff0000;
  }

  checkSpriteChange() {
    if (!this.currentField) return false;

    const isCorrectField = this.checkFieldIfCorrect(this.currentField);

    if (this.isCorrectField === isCorrectField) return false;

    this.isCorrectField = isCorrectField;

    this.setCorrectSprite();
  }

  checkFieldIfCorrect(field) {
    const { own, empty, withBuilding, withoutMainBuidling } = this.conditions;

    if (own && !field.ownedBy(this.kingdom)) return false;
    if (empty && !field.empty()) return false;
    if (withBuilding && field.empty()) return false;
    if (withoutMainBuidling && field.building && field.building.type === BUILDINGS.MAIN_BUILDING) return false;

    return true;
  }

  setActive(value) {
    if (value === this.active) return false;

    this.active = value;
  }

  pickField = (conditions = {}) => {
    this.pickedField = null;

    this.conditions = conditions;

    this.setActive(true);
  };

  hide() {
    this.setActive(false);
    this.setVisible(false);
  }
}
