import { COLORS } from 'common/consts/colors.js';
import { CARDS, EFFECTS, IMAGES } from 'common/consts/types/index.js';

import { TOOLTIP } from '../consts/tooltip.js';

export const FIXED = {
  [CARDS.PLACE_POWER_STATION]: {
    type: CARDS.PLACE_POWER_STATION,
    cost: 100,
    title: 'Power station',
    image: IMAGES.CARDS.IMAGES.PLACE_POWER_STATION,
    description: 'Drain DRAIN.\nPlace a power station on an own empty tile.',
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_POWER_STATION],
    stats: {
      drain: 400,
    },
    color: COLORS.CARD.GREEN,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.POWER_STATION],
  },
  [CARDS.ADD_ENERGY]: {
    type: CARDS.ADD_ENERGY,
    cost: 400,
    title: 'Power up',
    image: IMAGES.CARDS.IMAGES.ADD_ENERGY,
    description: 'Add ENERGY energy.',
    effects: [EFFECTS.ADD_ENERGY],
    stats: {
      energy: 200,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.PLACE_BASIC_CANNON]: {
    type: CARDS.PLACE_BASIC_CANNON,
    cost: 100,
    title: 'Basic cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_BASIC_CANNON,
    description: 'Drain DRAIN.\nPlace a basic cannon on a own empty tile.',
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_BASIC_CANNON],
    stats: {
      drain: 300,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.BASIC_CANNON],
  },
  [CARDS.PLACE_SNIPER_CANNON]: {
    type: CARDS.PLACE_SNIPER_CANNON,
    cost: 200,
    title: 'Sniper cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_SNIPER_CANNON,
    description: 'Drain DRAIN.\nPlace a sniper cannon on an own empty tile.',
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_SNIPER_CANNON],
    stats: {
      drain: 400,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.SNIPER_CANNON],
  },
  [CARDS.PLACE_FORGE]: {
    type: CARDS.PLACE_FORGE,
    cost: 200,
    title: 'Forge',
    image: IMAGES.CARDS.IMAGES.PLACE_FORGE,
    description: 'Drain DRAIN.\nPlace a forge on an own empty tile.',
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_FORGE],
    stats: {
      drain: 100,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.FORGE],
  },
  [CARDS.VOID]: {
    type: CARDS.VOID,
    cost: 200,
    title: 'Void',
    image: IMAGES.CARDS.IMAGES.VOID,
    description: 'Destroy.\nAdd VOID_POWER void to other players.',
    effects: [EFFECTS.DESTROY, EFFECTS.VOID_TO_OTHERS],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
    tooltipTypes: [TOOLTIP.TYPE.VOID],
  },
};
