import { EFFECTS, IMAGES } from 'common/consts/types/index.js';

import { EffectBase } from './effect-base.js';

export class PoisonEffect extends EffectBase {
  constructor(params) {
    super({ ...params, priority: 1 });

    this.power = params.power;

    this.type = EFFECTS.POISON;

    if (this.interfacePart && this.visible) this.createSprites();
  }

  getIconName() {
    return IMAGES.ICONS.POISON;
  }

  updateTexts() {
    if (!this.hasSprite()) return false;

    this.sprites.text.text = this.power;
  }

  hasSprite() {
    return !!this.spritesContainer;
  }

  onCycleEnd() {
    this.activatePoison();

    this.updateTexts();
  }

  onKingdomRedraw() {
    this.power--;

    if (this.power <= 0) {
      this.parent.remove(this.id);
    } else {
      this.parent.onEffectUpdate(this);
      this.updateTexts();
    }
  }

  activatePoison() {
    if (!this.advancedLogicPart) return;

    this.kingdom.loseFarFields(this.power);
  }

  update(params) {
    const { power } = params;

    if (power !== undefined) this.power = power;

    this.updateTexts();
  }

  increase(params) {
    this.power += params.power;
    this.updateTexts();
  }

  merge(poisonEffect) {
    this.power += poisonEffect.power;
    this.updateTexts();
  }

  toSocketData() {
    return {
      id: this.id,
      type: this.type,
      power: this.power,
    };
  }
}
