import { CARDS } from 'common/consts/types/index.js';

import { EnemyKingdom } from '../enemy.js';

export class SpreadKingdom extends EnemyKingdom {
  constructor(params) {
    super(params);

    if (this.difficulty >= 2) this.energyProduction += 2;
    if (this.difficulty >= 3) this.energyProduction += 4;
  }

  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.ENERGY_TO_BULLETS }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),
      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_MAIN }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));

    return cards;
  }
}
