import { Container, TilingSprite } from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';
import { InterfaceBase } from 'client/interface/interface-base.js';

import { IMAGES } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';

export class Background extends InterfaceBase {
  constructor(params) {
    super(params);

    this.createSprite();
    this.setPosition();
  }

  setPosition() {
    this.position = new Point(0, 0);

    this.spriteContainer.x = this.position.x;
    this.spriteContainer.y = this.position.y;
  }

  createSprite() {
    this.spriteContainer = new Container();
    this.spriteContainer.zIndex = Z_INDEX.BACKGROUND;

    this.sprite = new TilingSprite({
      texture: this.game.texturesManager.getTexture(IMAGES.ENTITIES.BACKGROUND_TILE),
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.spriteContainer.addChild(this.sprite);

    this.registerSprite(this.spriteContainer);
  }

  afterResize() {
    this.sprite.width = window.innerWidth;
    this.sprite.height = window.innerHeight;
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.spriteContainer);
  }
}
