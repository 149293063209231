import { capitalize } from '../helpers/prototype-extensions.js';

export class EventsController {
  constructor(game) {
    this.game = game;

    this.listeners = {};
  }

  addListener = (event, listener, params = {}) => {
    const { priority = 0 } = params;

    if (!this.listeners[event]) return (this.listeners[event] = [{ listener, priority }]);

    this.listeners[event].push({ listener, priority });
    this.listeners[event].sort((a, b) => a.priority - b.priority);
  };

  removeListener = (event, listener) => {
    this.listeners[event] = this.listeners[event].filter((record) => record.listener.id !== listener.id);
  };

  runEvent = (eventName, params = {}) => {
    const functionName = `on${capitalize(eventName)}`;
    const records = this.listeners[eventName];

    if (!records) return;

    for (let i = records.length - 1; i >= 0; i--) {
      const record = records[i];
      if (record.listener[functionName](params)) return;
    }
  };

  destroy = () => {
    this.listeners = {};
  };
}
