import { CARDS_DATA } from 'common/cards-data/index.js';
import { Card } from 'common/entities/card.js';
import { cloneObject } from 'common/helpers/data.js';

export class CardsCreator {
  constructor(game) {
    this.game = game;
  }

  createCard(params) {
    const { type, id } = params;

    return new Card({
      data: cloneObject(CARDS_DATA.ALL[type]),
      game: this.game,
      id,
      ...params,
    });
  }

  fromSocketData(socketData, owner) {
    const { id, type, stats, energyCost } = socketData;

    const data = cloneObject(CARDS_DATA.ALL[type]);

    if (typeof energyCost !== 'undefined') data.energyCost = energyCost;

    data.stats = { ...data.stats, ...stats };

    return new Card({
      id,
      data,
      owner,
      game: this.game,
    });
  }

  createBoughtCard(prototypeCard, kingdom, params) {
    const cardData = prototypeCard.getData();
    const { type } = cardData;

    const newCard = new Card({
      data: cloneObject(CARDS_DATA.ALL[type]),
      game: this.game,
      owner: kingdom,
      position: prototypeCard.getPosition(),
      ...params,
    });

    newCard.showFront();

    return newCard;
  }
}
