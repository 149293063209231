import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BasicCannon } from './basic.js';

export class SniperCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.fireInaccuracy = 0;
    this.range = HEXAGON_SIZE * 33;

    this.type = BUILDINGS.SNIPER_CANNON;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.SNIPER_CANNON;
  }
}
