import { BUILDINGS, EFFECTS, ENTITIES, ERRORS } from 'common/consts/types/index.js';
import { randomInWithoutRepeat } from 'common/helpers/random.js';

import { randomElement, randomize } from '../helpers/prototype-extensions.js';

export class CardEffectsExecutor {
  constructor(game) {
    this.game = game;
  }

  canBePlayed = (card, kingdom) => {
    const cardData = card.getData();
    const { effects, stats = {}, unplayable } = cardData;

    if (unplayable) return this.errorReturn('Unplayable', ERRORS.UNPLAYABLE);

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.DRAIN: {
          const { drain } = stats;

          if (drain && kingdom.getEnergy() < drain) {
            return this.errorReturn('Not enought energy!', ERRORS.NOT_ENOUGH_ENERGY);
          }

          break;
        }
        case EFFECTS.SNIPER_FIRE:
        case EFFECTS.SPREAD_SHOT:
        case EFFECTS.MULTISHOT:
        case EFFECTS.ENERGY_TO_BULLETS:
        case EFFECTS.SINGLE_FIRE:
        case EFFECTS.SHOT:
        case EFFECTS.FORTIFICATE_MAIN:
        case EFFECTS.FORTIFICATE_AROUND_MAIN: {
          if (!kingdom.getMainBuilding()) return this.errorReturn('You have no main building!');
          break;
        }
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_BASIC_CANNON:
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY:
        case EFFECTS.PLACE_MINE: {
          const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          if (ownEmptyFields.length === 0) {
            return this.errorReturn('No empty field on the map!');
          }

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const ownBuildings = this.game.mapController
            .getAllFields()
            .filter((field) => field.ownedBy(kingdom) && field.building && field.building.demolitionable);

          if (ownBuildings.length === 0) {
            return this.errorReturn('No buldings to destroy!');
          }

          break;
        }
        case EFFECTS.FORTIFICATION:
        case EFFECTS.FORTIFICATE_BORDERS: {
          const ownFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom));

          if (ownFields.length === 0) {
            return this.errorReturn('No own field on the map!');
          }

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const cards = kingdom.getHand();
          const possibleTarget = cards.find((targetCard) => targetCard.id !== card.id && targetCard.haveStat('power'));

          if (!possibleTarget) {
            return this.errorReturn('No possible target for power increase!');
          }

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const cards = kingdom.getHand();
          const possibleTarget = cards.find((targetCard) => targetCard.id !== card.id && targetCard.haveStat('multishot'));

          if (!possibleTarget) {
            return this.errorReturn('No possible target for multishot increase!');
          }

          break;
        }
      }
    }

    return this.confirmationReturn();
  };

  errorReturn(message, error) {
    return { can: false, error, message: message };
  }

  confirmationReturn() {
    return { can: true };
  }

  canBePlayedOnTarget(cardId, targets, kingdom) {
    const card = kingdom.searchForPlayableCard(cardId);
    if (!card) return this.errorReturn('Card not in hand!');

    const cardData = card.getData();
    const { effects, energyCost, suppliesCost } = cardData;

    if (energyCost && kingdom.getEnergy() < energyCost) {
      return this.errorReturn('Not enought energy!');
    }

    if (suppliesCost && kingdom.getSupplies() < suppliesCost) {
      return this.errorReturn('Not enought supplies!');
    }

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.SNIPER_FIRE:
        case EFFECTS.SPREAD_SHOT:
        case EFFECTS.MULTISHOT:
        case EFFECTS.ENERGY_TO_BULLETS:
        case EFFECTS.SINGLE_FIRE:
        case EFFECTS.SHOT:
        case EFFECTS.FORTIFICATE_MAIN:
        case EFFECTS.FORTIFICATE_AROUND_MAIN: {
          if (!kingdom.getMainBuilding()) return this.errorReturn('You have no main building!');
          break;
        }
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_BASIC_CANNON:
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_MINE: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.ownedBy(kingdom)) return this.errorReturn('This field is not yours!');
          if (!field.empty()) return this.errorReturn('This field is not empty!');

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.building) return this.errorReturn('No building on the field!');
          if (!field.building.demolitionable) return this.errorReturn("Can't be destroyed!");

          break;
        }
        case EFFECTS.FORTIFICATION: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.ownedBy(kingdom)) return this.errorReturn('This field is not yours!');

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          if (!targetCard.haveStat('power')) return this.errorReturn('Invalid card');
          if (!targetCard.isCardInHand()) return this.errorReturn('Card not in hand!');

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          if (!targetCard.haveStat('multishot')) return this.errorReturn('Invalid card');
          if (!targetCard.isCardInHand()) return this.errorReturn('Card not in hand!');

          break;
        }
      }
    }

    return this.confirmationReturn();
  }

  pickTargetsForCard = async (card, kingdom) => {
    const cardData = card.getData();
    const { effects } = cardData;

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.SPREAD_SHOT:
        case EFFECTS.SNIPER_FIRE:
        case EFFECTS.MULTISHOT:
        case EFFECTS.SINGLE_FIRE:
        case EFFECTS.SHOT: {
          return await kingdom.pickField();
        }

        case EFFECTS.ENERGY_TO_BULLETS: {
          return await kingdom.pickField({ effect: EFFECTS.ENERGY_TO_BULLETS });
        }
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_MINE:
        case EFFECTS.PLACE_BASIC_CANNON: {
          return await kingdom.pickField({ own: true, empty: true, effectCategory: 'building-placement' });
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          return await kingdom.pickField({ own: true, withBuilding: true, withoutMainBuilding: true });
        }
        case EFFECTS.TRASH_CARD: {
          return await kingdom.pickHandCard();
        }
        case EFFECTS.INCREASE_POWER: {
          return await kingdom.pickHandCard({ stat: 'power' });
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          return await kingdom.pickHandCard({ stat: 'multishot' });
        }
        case EFFECTS.FORTIFICATION: {
          return await kingdom.pickField({ own: true, effect: EFFECTS.FORTIFICATION });
        }
      }
    }

    return { cancel: false };
  };

  playCard = (cardId, targets, kingdom) => {
    const card = kingdom.searchForPlayableCard(cardId);

    if (!card) return false;

    const cardData = card.getData();
    const { stats, effects } = cardData;

    let willBeDestroyed = false;

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.DRAIN: {
          const { drain } = stats;

          kingdom.changeEnergy(-drain);

          break;
        }
        case EFFECTS.SPREAD_SHOT: {
          const { spread } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getMainBuilding().fireBullets({ spread, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.SHOT: {
          const { spread = 1, power = 1, piercing = 1, inaccuracy = 60, shots = 1 } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getMainBuilding().fireBulletsTask({ shots, inaccuracy, piercing, power, spread, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.INCREASE_SPREAD: {
          const { spreadIncrease } = stats;
          card.increaseStat('spread', spreadIncrease);

          break;
        }
        case EFFECTS.INCREASE_SHOTS: {
          const { shotsIncrease } = stats;
          card.increaseStat('shots', shotsIncrease);

          break;
        }
        case EFFECTS.SINGLE_FIRE: {
          const { power } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getMainBuilding().fireBullets({ power, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.SNIPER_FIRE: {
          const { shots } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getMainBuilding().fireBulletsTask({ shots, inaccuracy: 0, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.FAIR_SHOT: {
          const { shots } = stats;

          const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
          const targets = enemyKingdoms.map((kindom) => kindom.getMainBuilding().getField().getPosition());

          kingdom.getMainBuilding().fireBulletsTask({ shots, inaccuracy: 0, targets });

          break;
        }
        case EFFECTS.ENERGY_TO_BULLETS: {
          const { spread } = stats;

          const energy = kingdom.getEnergy();
          kingdom.changeEnergy(-energy);

          const shots = Math.floor(energy / 100);

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getMainBuilding().fireBulletsTask({ shots, spread, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.PLACE_BASIC_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.BASIC_CANNON);

          break;
        }
        case EFFECTS.PLACE_SNIPER_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.SNIPER_CANNON);

          break;
        }
        case EFFECTS.PLACE_SHORT_RANGE_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.SHORT_RANGE_CANNON);

          break;
        }
        case EFFECTS.PLACE_DEFENDER: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.DEFENDER);

          break;
        }
        case EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY: {
          const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          if (ownEmptyFields.length > 0) {
            const field = randomInWithoutRepeat(ownEmptyFields);
            this.game.buildingsCreator.createBuilding(field, BUILDINGS.RANDOM_CANNON);
          }

          if (ownEmptyFields.length > 0) {
            const field = randomInWithoutRepeat(ownEmptyFields);
            this.game.buildingsCreator.createBuilding(field, BUILDINGS.RANDOM_CANNON);
          }

          break;
        }
        case EFFECTS.PLACE_WAREHOUSE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.WAREHOUSE);

          break;
        }
        case EFFECTS.PLACE_FORGE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.FORGE);

          break;
        }
        case EFFECTS.PLACE_POWER_STATION: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.POWER_STATION);

          break;
        }
        case EFFECTS.PLACE_MINES_RANDOMLY: {
          const { numberOfMines } = stats;

          const allOwnFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          const ownRandomFields = randomize(allOwnFields);

          const max = Math.min(ownRandomFields.length, numberOfMines);

          for (let i = 0; i < max; i++) {
            const field = ownRandomFields[i];
            this.game.buildingsCreator.createBuilding(field, BUILDINGS.MINE);
          }

          break;
        }
        case EFFECTS.PLACE_MINE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.buildingsCreator.createBuilding(field, BUILDINGS.MINE);

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          field.destroyBuilding();

          break;
        }

        case EFFECTS.TRASH_CARD: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.trash();

          break;
        }
        case EFFECTS.MULTISHOT: {
          const { power, multishot } = stats;

          const mainBuilding = kingdom.getMainBuilding();

          const field = this.game.entitiesController.findById(targets.fieldId);
          mainBuilding.multishot(multishot, { power, target: field.getPosition() });

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.increaseStat('power', 1);

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.increaseStat('multishot', 1);

          break;
        }
        case EFFECTS.DRAW_CARDS: {
          const { cards } = stats;

          kingdom.orderDrawingCards(cards);

          break;
        }
        case EFFECTS.ADD_ENERGY: {
          const { energy } = stats;
          kingdom.changeEnergy(energy);

          break;
        }
        case EFFECTS.INCREASE_ENERGY_PRODUCTION: {
          const { energyProduction } = stats;
          kingdom.changeEnergyProduction(energyProduction);

          break;
        }
        case EFFECTS.REDUCE_ENERGY_COST_RANDOMLY: {
          const { energy } = stats;

          const cards = kingdom.getDeck();
          const discountableCards = cards.filter((card) => card.getEnergyCost());
          const card = randomElement(discountableCards);

          if (!card) break;

          card.changeEnergyCost(-energy);

          break;
        }
        case EFFECTS.POISON_TO_ALL: {
          const { poison } = stats;

          this.game.kingdomsController.eachKingdom((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.POISON, power: poison });
          });

          break;
        }
        case EFFECTS.VOID_TO_ALL: {
          const { voidPower } = stats;

          this.game.kingdomsController.eachKingdom((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.VOID, power: voidPower });
          });

          break;
        }
        case EFFECTS.VOID_TO_OTHERS: {
          const { voidPower } = stats;

          const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
          enemyKingdoms.forEach((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.VOID, power: voidPower });
          });

          break;
        }
        case EFFECTS.RANDOM_FORTIFICATION: {
          const { fortification, fields } = stats;

          const allOwnFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          const ownRandomFields = randomize(allOwnFields);

          const max = Math.min(fields, ownRandomFields.length);
          for (let i = 0; i < max; i++) {
            ownRandomFields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATION: {
          const { fortification } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          field.changeFortification(fortification);

          break;
        }
        case EFFECTS.FORTIFICATE_BORDERS: {
          const { fortification } = stats;

          const fields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.adjacentToEnemy());

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_MAIN: {
          const { fortification } = stats;

          const mainBuilding = kingdom.getMainBuilding();
          const mainField = mainBuilding.getFieldByHex();

          const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom));
          fields.push(mainField);

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_AROUND_MAIN: {
          const { fortification } = stats;

          const mainBuilding = kingdom.getMainBuilding();
          const mainField = mainBuilding.getFieldByHex();

          const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom) && field.isType(ENTITIES.FIELD));

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_BUILDINGS: {
          const { fortification } = stats;

          const fields = this.game.mapController
            .getAllFields()
            .filter((field) => field.ownedBy(kingdom) && field.building && field.building.building);

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.INCREASE_CARD_LIMIT: {
          kingdom.increaseCardsLimit(1);

          break;
        }
        case EFFECTS.DESTROY: {
          willBeDestroyed = true;

          break;
        }
        case EFFECTS.LIMITED_USAGE: {
          const { usage } = stats;

          if (usage <= 1) {
            willBeDestroyed = true;
          } else {
            card.increaseStat('usage', -1);
          }

          break;
        }
        case EFFECTS.REFILL: {
          kingdom.orderDrawingCards(1);

          break;
        }
      }
    }

    if (willBeDestroyed) {
      card.trash();
    }
  };

  cancelCard(kingdom, card) {
    const cardData = card.getData();
    const { energyCost } = cardData;

    kingdom.active.removeCard(card);
    kingdom.hand.addCard(card);

    if (energyCost) {
      kingdom.changeEnergy(energyCost);
    }
  }
}
