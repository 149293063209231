import { CARDS } from 'common/consts/types/index.js';

import { EnemyKingdom } from '../enemy.js';

export class RandomCannonKingdom extends EnemyKingdom {
  constructor(params) {
    super(params);

    if (this.difficulty >= 2) this.energyProduction += 2;
    if (this.difficulty >= 3) this.energyProduction += 4;
  }
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.PLACE_BASIC_CANNON }),
      this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }),
      this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.FORTIFICATE_MAIN }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.RANDOM_FORTIFICATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_MAIN }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_MAIN }));

    return cards;
  }
}
