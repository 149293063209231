import * as PIXI from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';

import { IMAGES } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';

import { EntityBase } from './entity-base.js';

const ZOOM_THRESHOLD = 0.5;

export class FortificationIcon extends EntityBase {
  constructor(params = {}) {
    super(params);

    this.position = new Point(params.x, params.y);

    this.owner = params.owner;
    this.mapField = params.mapField;

    this.createSprites();
  }

  createSprites() {
    this.createSpritesContainer();
    this.createIconSprite();
    this.createTextSprite();

    this.onFortificationUpdate();
    this.setTextVisibility(this.game.interfaceController.getZoom() >= ZOOM_THRESHOLD);

    this.spritesContainer.x = this.position.x;
    this.spritesContainer.y = this.position.y + 45;
  }

  createSpritesContainer() {
    this.spritesContainer = new PIXI.Container();
    this.spritesContainer.zIndex = Z_INDEX.FORTIFICATION;

    this.registerMapSprite(this.spritesContainer);
  }

  createIconSprite() {
    this.iconSprite = this.game.texturesManager.createStandardSprite(IMAGES.ICONS.FORTIFICATION);

    if (this.owner) this.iconSprite.tint = this.owner.color;

    this.spritesContainer.addChild(this.iconSprite);
  }

  changeOwner(owner) {
    this.owner = owner;
    this.iconSprite.tint = owner ? owner.color : 0xffffff;
  }

  createTextSprite() {
    this.fortificationText = new PIXI.Text('', {
      fontFamily: 'Arial',
      fontSize: 14,
      fill: 0xffffff,
    });
    this.fortificationText.anchor.set(0.5);

    this.spritesContainer.addChild(this.fortificationText);
  }

  onFortificationUpdate() {
    const fortification = this.mapField.getFortification();
    this.fortificationText.text = fortification;
  }

  setTextVisibility(visible) {
    this.fortificationText.visible = visible;
  }

  onZoom(zoomValue) {
    this.setTextVisibility(zoomValue >= ZOOM_THRESHOLD);
  }

  destroy() {
    super.destroy();
    this.removeMapSprite(this.spritesContainer);
  }
}
