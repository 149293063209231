import { COLORS } from 'common/consts/colors.js';
import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARDS, EFFECTS, IMAGES } from 'common/consts/types/index.js';

export const ADDITIONAL = {
  [CARDS.JUNK]: {
    type: CARDS.JUNK,
    title: 'Junk',
    image: IMAGES.CARDS.IMAGES.JUNK,
    description: 'Unplayable.',
    color: COLORS.CARD.GRAY,
    unplayable: true,
  },
};
