import { ENTITIES } from 'common/consts/types/index.js';
import { HexPosition } from 'common/data-types/hex-position.js';
import { MapField } from 'common/entities/map-field.js';
import { Wall } from 'common/entities/wall-field.js';

const STARING_POSITION_OFFSET = 2;
const HALF_OFFSET = Math.round(STARING_POSITION_OFFSET / 2);

export class MapController {
  constructor(game) {
    this.map = null;
    this.allFields = [];
    this.game = game;
  }

  setSize(size) {
    this.size = size;
    this.sideSize = (size - 1) / 2;
    this.realSideSize = (size + 1) / 2;
  }

  prepareMap(params) {
    const { size } = params;

    this.setSize(size);

    this.generateStartingMap();
    this.generateFieldsArray();
  }

  destroyMap() {
    this.everyMapField((field) => field.destroy());
  }

  generateStartingMap() {
    this.map = [];

    for (let col = 0; col < this.size; col++) {
      this.map[col] = [];
    }

    for (let row = 0; row < this.size; row++) {
      const toCenterRow = Math.abs(this.sideSize - row);
      const rowSize = this.size - toCenterRow;
      const start = Math.floor(toCenterRow / 2);

      for (let col = start; col < start + rowSize; col++) {
        let Klass = MapField;

        if (row === 0 || row === this.size - 1 || col === start || col === start + rowSize - 1) {
          Klass = Wall;
        }

        this.map[col][row] = new Klass({
          hexPosition: new HexPosition(col, row),
          game: this.game,
        });
      }
    }
  }

  generateFieldsArray() {
    this.allFields = [];

    for (let row = 0; row < this.size; row++) {
      const toCenterRow = Math.abs(this.sideSize - row);
      const rowSize = this.size - toCenterRow;
      const start = Math.floor(toCenterRow / 2);

      for (let col = start; col < start + rowSize; col++) {
        this.allFields.push(this.map[col][row]);
      }
    }
  }

  getFieldByHex(hexPosition) {
    return this.map[hexPosition.col] && this.map[hexPosition.col][hexPosition.row];
  }

  getFieldById(id) {
    return this.allFields.find((field) => field.id === id);
  }

  getCenterHexPosition() {
    return new HexPosition(this.sideSize, this.sideSize);
  }

  getTopLeftHexPosition() {
    return new HexPosition(Math.floor(this.realSideSize / 2) + HALF_OFFSET, 1 + STARING_POSITION_OFFSET);
  }

  getTopRightHexPosition() {
    return new HexPosition(this.size - Math.floor(this.realSideSize / 2) - 1 - HALF_OFFSET, 1 + STARING_POSITION_OFFSET);
  }

  getLeftHexPosition() {
    return new HexPosition(1 + STARING_POSITION_OFFSET, this.realSideSize - 1);
  }

  getRightHexPosition() {
    return new HexPosition(this.size - 2 - STARING_POSITION_OFFSET, this.realSideSize - 1);
  }

  getBottomLeftHexPosition() {
    return new HexPosition(Math.floor(this.realSideSize / 2) + HALF_OFFSET, this.size - 2 - STARING_POSITION_OFFSET);
  }

  getBottomRightHexPosition() {
    return new HexPosition(this.size - Math.floor(this.realSideSize / 2) - 1 - HALF_OFFSET, this.size - 2 - STARING_POSITION_OFFSET);
  }

  getAllStartingFields() {
    return [
      this.getFieldByHex(this.getLeftHexPosition()),
      this.getFieldByHex(this.getTopLeftHexPosition()),
      this.getFieldByHex(this.getTopRightHexPosition()),
      this.getFieldByHex(this.getRightHexPosition()),
      this.getFieldByHex(this.getBottomRightHexPosition()),
      this.getFieldByHex(this.getBottomLeftHexPosition()),
    ];
  }

  activate(delta) {
    this.everyMapField((mapField) => {
      mapField && mapField.activate(delta);
    });
  }

  onCycleEnd() {
    this.everyMapField((mapField) => {
      mapField.onCycleEnd();
    });
  }

  rotateBuildingsForKingdom(kingdom) {
    this.everyMapField((mapField) => {
      if (mapField.ownedBy(kingdom)) {
        mapField.rotateBuilding(kingdom.target);
      }
    });
  }

  getFieldByCoords(col, row) {
    if (col < 0 || row < 0) return null;
    if (col >= this.size || row >= this.size) return null;

    return this.map[col][row];
  }

  everyMapField(callback) {
    this.allFields.forEach(callback);
  }

  toSocketData() {
    return { size: this.size, fields: this.fieldsToSocketData() };
  }

  loadFromSD(data) {
    const { size, fields } = data;
    this.setSize(size);
    this.fieldsFromSocketData(fields);
  }

  fieldsToSocketData() {
    const data = [];

    this.everyMapField((field) => {
      data.push(field.toSocketData());
    });

    return data;
  }

  updateFieldsFromSD(fieldsData) {
    fieldsData.map(this.updateFieldFromSocketData);
  }

  updateFieldFromSocketData = (data) => {
    const { id } = data;
    const field = this.getFieldById(id);
    if (!field) return;

    field.updateFromSocketData(data);
  };

  fieldsFromSocketData(fields) {
    this.destroyMap();

    this.map = [];

    for (let col = 0; col < this.size; col++) {
      this.map[col] = [];
    }

    let fieldIndex = 0;
    for (let row = 0; row < this.size; row++) {
      const toCenterRow = Math.abs(this.sideSize - row);
      const rowSize = this.size - toCenterRow;
      const start = Math.floor(toCenterRow / 2);

      for (let col = start; col < start + rowSize; col++) {
        const { id, type, hostId, building, fortification } = fields[fieldIndex];

        let Klass = MapField;

        if (type === ENTITIES.WALL) Klass = Wall;

        const field = new Klass({
          id: id,
          hexPosition: new HexPosition(col, row),
          game: this.game,
        });

        this.map[col][row] = field;

        if (hostId) {
          const kingdom = this.game.kingdomsController.getKingdomById(hostId);
          field.setOwner(kingdom);
        }

        if (fortification) field.setFortification(fortification);

        if (building) {
          this.game.buildingsCreator.setBuildingFromSD(field, building);
        }

        fieldIndex++;
      }
    }

    this.generateFieldsArray();
  }

  getAllFields() {
    return this.allFields;
  }

  numberOfGrayTiles() {
    let count = 0;

    this.everyMapField((field) => {
      if (field.ownedBy(null)) {
        count++;
      }
    });

    return count;
  }
}
