import { BUILDINGS } from 'common/consts/types/index.js';
import { BasicCannon } from 'common/entities/buildings/cannons/basic.js';
import { DefenderCannon } from 'common/entities/buildings/cannons/defender.js';
import { RandomCannon } from 'common/entities/buildings/cannons/random.js';
import { ShortRangeCannon } from 'common/entities/buildings/cannons/short-range.js';
import { SniperCannon } from 'common/entities/buildings/cannons/sniper.js';
import { PowerPlant } from 'common/entities/buildings/economy/power-plant.js';
import { Warehouse } from 'common/entities/buildings/economy/warehouse.js';
import { Forge } from 'common/entities/buildings/forge.js';
import { MainBuilding } from 'common/entities/buildings/main-building.js';
import { Mine } from 'common/entities/buildings/mine.js';

export class BuildingsCreator {
  constructor(game) {
    this.buildings = {
      [BUILDINGS.BASIC_CANNON]: BasicCannon,
      [BUILDINGS.MAIN_BUILDING]: MainBuilding,
      [BUILDINGS.RANDOM_CANNON]: RandomCannon,
      [BUILDINGS.SHORT_RANGE_CANNON]: ShortRangeCannon,
      [BUILDINGS.SNIPER_CANNON]: SniperCannon,
      [BUILDINGS.DEFENDER]: DefenderCannon,
      [BUILDINGS.WAREHOUSE]: Warehouse,
      [BUILDINGS.POWER_STATION]: PowerPlant,
      [BUILDINGS.FORGE]: Forge,
      [BUILDINGS.MINE]: Mine,
    };

    this.game = game;
    this.serverPart = game.serverPart;
  }

  setBuildingFromSD(mapField, socketData) {
    const { type, id, hostId } = socketData;

    const building = new this.buildings[type]({
      mapField,
      game: this.game,
      id,
    });

    mapField.setBuilding(building);

    if (type === BUILDINGS.MAIN_BUILDING) {
      const ownerKingdom = this.game.kingdomsController.getKingdomById(hostId);
      if (ownerKingdom) ownerKingdom.setMainBuilding(building);
    }

    return building;
  }

  createBuildingFromSD(mapField, socketData) {
    const building = this.setBuildingFromSD(mapField, socketData);
    this.game.eventsController.runEvent('createBuilding', { building });
  }

  setBuilding(mapField, type, params) {
    const building = new this.buildings[type]({
      mapField,
      game: this.game,
      ...params,
    });

    mapField.setBuilding(building);

    if (this.game.shouldSendEvent()) this.game.connectionController.onFieldUpdate(mapField);

    return building;
  }

  createBuilding(mapField, type, params) {
    const building = this.setBuilding(mapField, type, params);
    this.game.eventsController.runEvent('createBuilding', { building });
  }
}
