import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, ENTITIES, IMAGES } from 'common/consts/types/index.js';
import { degreesToRadians, perFrameSpeed, perFrameTimer, rotationToSpeeds } from 'common/helpers/index.js';

import { BasicCannon } from './basic.js';

export class DefenderCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * 4;
    this.rotate = false;

    this.type = BUILDINGS.DEFENDER;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.DEFENDER;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.fireBullets();
  }

  fireBullets() {
    let rotation = degreesToRadians(30);

    for (let i = 6; i > 0; i--) {
      this.game.entitiesController.create(ENTITIES.BULLET, {
        x: this.position.x,
        y: this.position.y,
        ...rotationToSpeeds(rotation, perFrameSpeed(this.bulletSpeed)),
        lifeTime: perFrameTimer(this.range / this.bulletSpeed),
        owner: this.owner,
      });

      rotation += degreesToRadians(60);
    }
  }
}
