import { uniqueString } from 'common/helpers/data.js';

export const BUILDINGS = {
  MAIN_BUILDING: uniqueString('B'),
  RANDOM_CANNON: uniqueString('B'),
  BASIC_CANNON: uniqueString('B'),
  SHORT_RANGE_CANNON: uniqueString('B'),
  SNIPER_CANNON: uniqueString('B'),
  DEFENDER: uniqueString('B'),

  WAREHOUSE: uniqueString('B'),
  POWER_STATION: uniqueString('B'),
  FORGE: uniqueString('B'),

  MINE: uniqueString('B'),
};
