import { perFrameTimer } from 'common/helpers/converters.js';

export const MAP_STANDARD_SIZE = 35;

export const STARTING_MAX_CARDS = 6;
export const HARD_CARD_LIMIT = 20;

export const STARTING_ENERGY_PRODUCTION = 0;
export const STARTING_ENERGY = STARTING_ENERGY_PRODUCTION;

export const CYCLES_PER_REDRAW = 6;
export const DEFAULT_CYCLE_TIME = 5;

export const SHUFFILING_TIMER = perFrameTimer(2);
export const DRAWING_TIMER = perFrameTimer(0.2);

export const CARD_IN_HAND_TIME = perFrameTimer(0.5);
export const CARD_TRASH_TIME = perFrameTimer(0.15);
export const CARD_INACTIVE_TIME = perFrameTimer(0.5);

export const COUNTDOWN_TO_START = 10;
