import { Z_INDEX } from 'client/consts/z-index.js';
import { Box } from 'client/interface/elements/box.js';
import { Button } from 'client/interface/elements/button.js';
import { Overlay } from 'client/interface/elements/overlay.js';
import { Text } from 'client/interface/elements/text.js';
import { CentredInterfaceGroup } from 'client/interface/groups/centred-interface-group.js';

const BUTTON_TOP_MARGIN = 20;
const GAP = 5;
const PADDING = 10;

const TITLE_SIZE = 40;
const DESCRIPTION_SIZE = 20;

const WIDTH = 450;

export class EndMessage extends CentredInterfaceGroup {
  constructor(params) {
    super(params);

    this.title = '';
    this.description = '';

    this.width = WIDTH;

    this.gap = GAP;
    this.padding = PADDING;

    this.createSubElements();
    this.registerEventListeners();
  }

  registerEventListeners() {
    this.game.eventsController.addListener('escKeyDown', this);
  }

  onEscKeyDown() {
    if (!this.visible) return false;

    this.close();
    this.game.interfaceController.unpauseGame();

    return true;
  }

  showYouWon() {
    this.showMessage('Game Over', 'You won! Well done.');
  }

  showYouLost() {
    this.showMessage('Game Over', 'You were eliminated.');
  }

  showCustom(message) {
    this.showMessage('Game Over', message);
  }

  showMessage(title, description) {
    this.title = title;
    this.description = description;

    this.updateSprites();
    this.setElementsPositions();
    this.setBoxSize();
    this.setVisibility(true);
  }

  close() {
    this.setVisibility(false);
  }

  createSubElements() {
    this.registerElement(
      'overlay',
      new Overlay({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'box',
      new Box({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'title',
      new Text({
        text: this.title,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TITLE_SIZE,
      })
    );

    this.registerElement(
      'description',
      new Text({
        text: this.description,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: DESCRIPTION_SIZE,
      })
    );

    this.registerElement(
      'watchButton',
      new Button({
        text: 'Watch',
        callback: this.onWatch,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        marginTop: BUTTON_TOP_MARGIN,
      })
    );

    this.registerElement(
      'quitButton',
      new Button({
        text: 'Quit',
        callback: this.onQuit,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );
  }

  setBoxSize() {
    this.elements.box.setSize(this.width, this.height);
  }

  onWatch = () => {
    this.close();
    this.game.interfaceController.unpauseGame();
  };

  onQuit = () => {
    setTimeout(this.game.destroy, 1);
  };

  updateSprites() {
    this.updateTitleText();
    this.updateDescriptionText();
  }

  updateTitleText() {
    this.elements.title.setText(this.title);
  }

  updateDescriptionText() {
    this.elements.description.setText(this.description);
  }

  afterResize() {
    if (!this.visible) return false;

    this.setElementsPositions();
  }
}
