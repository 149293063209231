import { ADDITIONAL } from './additional.js';
import { FIXED } from './fixed.js';
import { RANDOM } from './random.js';
import { STARTING } from './starting.js';

export const CARDS_DATA = {
  ALL: { ...STARTING, ...FIXED, ...RANDOM, ...ADDITIONAL },
  RANDOM,
  FIXED,
};
