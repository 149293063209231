export function randomIndex(array) {
  return Math.floor(Math.random() * array.length);
}

export function randomElement(array) {
  return array[randomIndex(array)];
}

export function isArrayEmpty(array) {
  return array.length < 1;
}

export function removeRandom(array) {
  const index = randomIndex(array);
  const element = array[index];

  array.splice(index, 1);

  return element;
}

export function randomize(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export function randomElements(array, number = 1) {
  const result = [];

  const cloneArray = [].concat(array);

  const max = Math.min(cloneArray.length, number);
  for (let i = 0; i < max; i++) {
    const maxIndex = cloneArray.length - 1;

    const index = Math.round(Math.random() * maxIndex);
    result.push(cloneArray[index]);

    cloneArray.splice(index, 1);
  }

  return result;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
