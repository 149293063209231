import { Point } from 'common/data-types/point.js';

const LEFT_KEYCODE = 65; // a
const TOP_KEYCODE = 87; // w
const RIGHT_KEYCODE = 68; // d
const DOWN_KEYCODE = 83; // s

const TEST_KEYCODE = 70; // f - test key
const ZOOM_OUT_KEYCODE = 90; // z
const ZOOM_IN_KEYCODE = 88; // x
const ESC_KEYCODE = 27; // ESC

const INFO_KEYCODE = 16; // SHIFT

export class InputsController {
  constructor(game) {
    this.game = game;

    this.mousePosition = new Point();
    this.mouseMapPosition = new Point();

    this.resizeTimer = null;

    this.lastPressed = '';
  }

  startHandling() {
    this.handleMouseInput();
    this.handleKeyboardInput();
    this.handleWindow();
    this.handlePixiApp();
  }

  cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    return false;
  }

  handleMouseInput() {
    const element = document.getElementsByTagName('canvas')[0];

    element.addEventListener('contextmenu', this.cancelEvent);

    document.addEventListener('mousewheel', this.onMouseWheel, { passive: false });
  }

  handleWindow() {
    window.addEventListener('resize', this.onResize);
  }

  handleKeyboardInput() {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
  }

  handlePixiApp() {
    this.game.pixiApp.stage.interactive = true;

    this.game.pixiApp.stage.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseWheel = (event) => {
    event.preventDefault();

    if (event.deltaY < 0) {
      this.game.eventsController.runEvent('mouseWheelUp');
    } else {
      this.game.eventsController.runEvent('mouseWheelDown');
    }
  };

  onZoomInDown = () => {
    this.game.interfaceController.setZoomingIn(true);
  };

  onZoomOutDown = () => {
    this.game.interfaceController.setZoomingOut(true);
  };

  onZoomInUp = () => {
    this.game.interfaceController.setZoomingIn(false);
  };

  onZoomOutUp = () => {
    this.game.interfaceController.setZoomingOut(false);
  };

  onDevToolsActivate = () => {
    this.game.toggleDevTools();
  };

  onKeyDown = (event) => {
    this.saveKey(event);

    switch (event.keyCode || event.charCode) {
      case LEFT_KEYCODE:
        return this.onLeftKeyDown();
      case RIGHT_KEYCODE:
        return this.onRightKeyDown();
      case DOWN_KEYCODE:
        return this.onDownKeyDown();
      case TOP_KEYCODE:
        return this.onUpKeyDown();
      case TEST_KEYCODE:
        return this.onTestDown();
      case ZOOM_IN_KEYCODE:
        return this.onZoomInDown();
      case ZOOM_OUT_KEYCODE:
        return this.onZoomOutDown();
      case ESC_KEYCODE:
        return this.game.eventsController.runEvent('escKeyDown');
      case INFO_KEYCODE:
        return this.onInfoDown();
      default:
    }
  };

  saveKey(event) {
    this.lastPressed += event.key;

    if (this.lastPressed.length > 3) {
      this.lastPressed = this.lastPressed.slice(-3);
    }

    if (this.lastPressed === 'dev') {
      this.onDevToolsActivate();
    }
  }

  onKeyUp = (event) => {
    switch (event.keyCode || event.charCode) {
      case LEFT_KEYCODE:
        return this.onLeftKeyUp();
      case RIGHT_KEYCODE:
        return this.onRightKeyUp();
      case DOWN_KEYCODE:
        return this.onDownKeyUp();
      case TOP_KEYCODE:
        return this.onUpKeyUp();
      case ZOOM_IN_KEYCODE:
        return this.onZoomInUp();
      case ZOOM_OUT_KEYCODE:
        return this.onZoomOutUp();
      case INFO_KEYCODE:
        return this.onInfoUp();
      default:
    }
  };

  onLeftKeyDown() {
    this.game.interfaceController.setMovingLeft(true);
  }

  onRightKeyDown() {
    this.game.interfaceController.setMovingRight(true);
  }

  onDownKeyDown() {
    this.game.interfaceController.setMovingDown(true);
  }

  onUpKeyDown() {
    this.game.interfaceController.setMovingUp(true);
  }

  onInfoDown() {
    this.game.eventsController.runEvent('infoKeyDown');
  }

  onInfoUp() {
    this.game.eventsController.runEvent('infoKeyUp');
  }

  onLeftKeyUp() {
    this.game.interfaceController.setMovingLeft(false);
  }

  onRightKeyUp() {
    this.game.interfaceController.setMovingRight(false);
  }

  onDownKeyUp() {
    this.game.interfaceController.setMovingDown(false);
  }

  onUpKeyUp() {
    this.game.interfaceController.setMovingUp(false);
  }

  onTestDown() {
    const cardId = this.game.mainKingdom.hand.interface.focusedCardId;
    this.game.mainKingdom.dropCard(cardId);
  }

  onResize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.afterResize, 100);
  };

  afterResize = () => {
    this.game.pixiApp.renderer.resize(window.innerWidth, window.innerHeight);
    this.game.entitiesController.afterResize();
  };

  onMouseMove = (event) => {
    const point = event.data.global;

    this.mousePosition.set(point.x, point.y);
    this.mouseMapPosition = this.game.interfaceController.globalPointToMapPoint(this.mousePosition);

    const position = this.mousePosition.clone();

    this.game.eventsController.runEvent('mouseMove', { position });
  };

  getMouseMapPosition = () => {
    return this.mouseMapPosition;
  };

  activate() {
    this.mouseMapPosition = this.game.interfaceController.globalPointToMapPoint(this.mousePosition);
  }

  destroy() {
    const element = document.getElementsByTagName('canvas')[0];

    if (element) element.removeEventListener('contextmenu', this.cancelEvent);

    document.removeEventListener('mousewheel', this.onMouseWheel);

    window.removeEventListener('resize', this.onResize);

    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }
}
