import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BasicCannon } from './basic.js';

export class ShortRangeCannon extends BasicCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * 12;

    this.type = BUILDINGS.SHORT_RANGE_CANNON;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.SHORT_RANGE_CANNON;
  }
}
