import { CARDS_DATA } from 'common/cards-data/index.js';

import { COLORS } from '../consts/colors.js';
import { objectToArray } from '../helpers/data.js';
import { randomElements } from '../helpers/prototype-extensions.js';

const NUMBER_OF_RANDOM_CARDS = 10;

export class MarketController {
  constructor(game) {
    this.game = game;

    this.choices = { fixed: [], random: [] };
  }

  tryBuyCard = (prototypeCard, kingdom) => {
    if (!this.canCardBePaid(prototypeCard.getData(), kingdom)) return false;

    if (this.game.advancedLogicPart) return this.buyCard(prototypeCard, kingdom);
    if (this.game.shouldConfirmWithHost()) return this.sendBuyRequest(prototypeCard, kingdom);
  };

  sendBuyRequest(prototypeCard, kingdom) {
    const cardData = prototypeCard.getData();
    const { type } = cardData;

    this.game.connectionController.sendBuyCard({ cardType: type }, { prototypeId: prototypeCard.getId(), kingdomId: kingdom.getId() });
  }

  afterCardBuyRequest = (data) => {
    const {
      cardId,
      callbackData: { prototypeId, kingdomId },
    } = data;

    const prototypeCard = this.game.entitiesController.findById(prototypeId);
    const kingdom = this.game.kingdomsController.getKingdomById(kingdomId);

    const newCard = this.game.cardsCreator.createBoughtCard(prototypeCard, kingdom, { id: cardId });

    kingdom.addToDiscard(newCard);
  };

  buyCard(prototypeCard, kingdom) {
    this.payForCard(prototypeCard.getData(), kingdom);

    const newCard = this.game.cardsCreator.createBoughtCard(prototypeCard, kingdom);

    kingdom.addToDiscard(newCard);
  }

  validatePick(cardType, kingdom) {
    const cardData = this.getCardDataByType(cardType);

    if (!this.canCardBePaid(cardData, kingdom)) return false;
    if (!this.isCardTypeAvailable(cardType)) return false;

    return true;
  }

  canCardBePaid(cardData, kingdom) {
    const { cost } = cardData;

    if (kingdom.getEnergy() < cost) return false;

    return true;
  }

  payForCardType(cardType, kingdom) {
    this.payForCard(this.getCardDataByType(cardType), kingdom);
  }

  payForCard(cardData, kingdom) {
    const { cost = 0 } = cardData;
    kingdom.changeEnergy(-cost);
  }

  getCardDataByType(cardType) {
    return CARDS_DATA.ALL[cardType];
  }

  isCardTypeAvailable(cardType) {
    return this.choices.fixed.find((type) => type === cardType) || this.choices.random.find((type) => type === cardType);
  }

  prepareChoices() {
    this.prepareFixed();
    this.prepareRandom();
  }

  prepareFixed() {
    const fixedCardsData = objectToArray(CARDS_DATA.FIXED);

    const sortedCardsData = fixedCardsData.sort(this.sortCardsData);

    this.choices.fixed = sortedCardsData.map((cardData) => cardData.type);
  }

  prepareRandom() {
    const randomCardsData = objectToArray(CARDS_DATA.RANDOM);

    const cardsData = randomElements(randomCardsData, NUMBER_OF_RANDOM_CARDS).sort(this.sortCardsData);

    this.choices.random = cardsData.map((cardData) => cardData.type);
  }

  sortCardsData = (cardData1, cardData2) => {
    const colorDiff = this.colorToValue(cardData1.color) - this.colorToValue(cardData2.color);

    if (colorDiff !== 0) return colorDiff;

    return cardData1.scienceCost - cardData2.scienceCost;
  };

  colorToValue(color) {
    switch (color) {
      case COLORS.CARD.GREEN:
        return 1;
      case COLORS.CARD.BLUE:
        return 2;

      case COLORS.CARD.RED:
        return 3;
      default:
        return 4;
    }
  }

  getFixedChoices() {
    return this.choices.fixed;
  }

  getRandomChoices() {
    return this.choices.random;
  }

  getChoices(choices) {
    if (choices === 'fixed') return this.getFixedChoices();
    if (choices === 'random') return this.getRandomChoices();

    return [];
  }

  toSocketData() {
    return {
      fixed: this.choices.fixed,
      random: this.choices.random,
    };
  }

  loadFromSD(data) {
    this.choices.fixed = data.fixed;
    this.choices.random = data.random;

    this.game.eventsController.runEvent('marketReload');
  }
}
