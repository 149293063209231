import * as PIXI from 'pixi.js';

import { HEXAGON_HEIGHT, HEXAGON_RADIUS } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { BuildingRing } from 'client/entities/particles/building-ring.js';
import { ActionButtons } from 'client/interface/action-buttons.js';
import { Background } from 'client/interface/background.js';
import { ControlledKingdomInterface } from 'client/interface/controlled-kingdom.js';
import { CycleBar } from 'client/interface/cycle-bar.js';
import { EndMessage } from 'client/interface/groups/end-message.js';
import { PauseMenu } from 'client/interface/groups/pause-menu.js';
import { SettingsMenu } from 'client/interface/groups/settings-menu.js';
import { InfoUnderBar } from 'client/interface/info-under-bar.js';
import { MapScrolling } from 'client/interface/map-scrolling.js';
import { StatusInfo } from 'client/interface/status-info.js';

import { Point } from 'common/data-types/point.js';

import { FocusArea } from '../interface/elements/focus-area.js';
import { MessageModal } from '../interface/groups/message-modal.js';
import { Tooltip } from '../interface/groups/tooltip.js';
import { MarketBoard } from '../interface/market-board.js';

const MOVE_SPEED = 1000 / 60;
const ZOOMIG_CHANGE = 1.03;

export class InterfaceController {
  constructor(game) {
    this.game = game;
    this.stage = this.game.pixiApp.stage;

    this.xViewport = 0;
    this.yViewport = 0;

    this.zoom = 0.5;

    this.movingLeft = false;
    this.movingRight = false;
    this.movingTop = false;
    this.movingBottom = false;

    this.zoomingIn = false;
    this.zoomingOut = false;

    this.interactive = true;
  }

  createInterface() {
    this.background = new Background({ game: this.game });
    this.cycleBar = new CycleBar({ game: this.game });
    this.infoUnderBar = new InfoUnderBar({ game: this.game });

    this.mapScrolling = new MapScrolling({ game: this.game });
    this.statusInfo = new StatusInfo({ game: this.game });

    this.actionButtons = new ActionButtons({ game: this.game });

    this.fixedMarketBoard = new MarketBoard({ game: this.game, type: 'fixed' });
    this.randomMarketBoard = new MarketBoard({ game: this.game, type: 'random' });

    this.endMessage = new EndMessage({ game: this.game });
    this.pauseMenu = new PauseMenu({ game: this.game });
    this.settingsMenu = new SettingsMenu({ game: this.game });

    this.tooltip = new Tooltip({ game: this.game });

    /*
    this.focusArea = new FocusArea({ game: this.game, visible: true, x: 300, y: 300 });
    this.messageModal = new MessageModal({ game: this.game });
    this.messageModal.showMessage('tetst');*/
  }

  createMainKingdomInterface() {
    this.controlledKingdomInterface = new ControlledKingdomInterface({
      game: this.game,
      kingdom: this.game.kingdomsController.getMainKingdom(),
    });
  }

  makeMaingKingdomInterfaceInteractive() {
    this.controlledKingdomInterface.makeInteractive();
  }

  setInteractive(value) {
    this.interactive = value;
  }

  leftOffset() {
    return this.game.mapController.sideSize * HEXAGON_HEIGHT * 2;
  }

  topOffset() {
    return this.game.mapController.sideSize * HEXAGON_RADIUS * 1.5;
  }

  getZoom() {
    return this.zoom;
  }

  setMovingLeft(value) {
    this.movingLeft = value;
  }

  setMovingRight(value) {
    this.movingRight = value;
  }

  setMovingUp(value) {
    this.movingUp = value;
  }

  setMovingDown(value) {
    this.movingDown = value;
  }

  setZoomingOut(value) {
    this.zoomingOut = value;
  }

  setZoomingIn(value) {
    this.zoomingIn = value;
  }

  scaleViewPoint(scale) {
    this.xViewport *= 1 / scale;
    this.yViewport *= 1 / scale;

    this.xViewport += (window.innerWidth / 2) * (1 - 1 / scale);
    this.yViewport += (window.innerHeight / 2) * (1 - 1 / scale);

    this.setSpriteContainerPosition();
  }

  onMouseWheelUp = () => {
    if (!this.interactive) return false;

    this.changeZoom(1.1);
  };

  onMouseWheelDown = () => {
    if (!this.interactive) return false;

    this.changeZoom(1 / 1.1);
  };

  changeZoom = (change) => {
    if (change === 1) return;

    const startingZoom = this.zoom;

    this.zoom *= change;

    if (this.zoom > 1) this.zoom = 1;
    if (this.zoom < 0.1) this.zoom = 0.1;

    const difference = startingZoom / this.zoom;

    this.mapContainer.scale.set(this.zoom);
    this.mapInterfaceContainer.scale.set(this.zoom);
    this.scaleViewPoint(difference);

    this.game.entitiesController.onZoom(this.zoom);
  };

  setZoom = (value) => {
    this.changeZoom(value / this.zoom);
  };

  configure() {
    this.createContainers();
    this.setViewportToCenter();
    this.registerEventListeners();
  }

  setViewportToCenter() {
    this.xViewport = this.game.pixiApp.screen.width / 2 - this.leftOffset() * this.zoom;
    this.yViewport = this.game.pixiApp.screen.height / 2 - this.topOffset() * this.zoom;

    this.setSpriteContainerPosition();
  }

  createContainers() {
    this.mainContainer = new PIXI.Container();
    this.mainContainer.sortableChildren = true;

    this.mapContainer = new PIXI.Container();
    this.mapContainer.scale.set(this.zoom);
    this.mapContainer.sortableChildren = true;
    this.mapContainer.zIndex = Z_INDEX.MAP;

    this.mapInterfaceContainer = new PIXI.Container();
    this.mapInterfaceContainer.scale.set(this.zoom);
    this.mapInterfaceContainer.sortableChildren = true;
    this.mapInterfaceContainer.zIndex = Z_INDEX.MAP_INTERFACE;

    this.stage.addChild(this.mainContainer);

    this.mainContainer.addChild(this.mapContainer);
    this.mainContainer.addChild(this.mapInterfaceContainer);

    this.setSpriteContainerPosition();
  }

  setSpriteContainerPosition() {
    this.mapContainer.x = this.xViewport;
    this.mapContainer.y = this.yViewport;

    this.mapInterfaceContainer.x = this.xViewport;
    this.mapInterfaceContainer.y = this.yViewport;
  }

  registerEventListeners() {
    this.game.eventsController.addListener('escKeyDown', this);
    this.game.eventsController.addListener('mouseWheelUp', this);
    this.game.eventsController.addListener('mouseWheelDown', this);

    this.game.eventsController.addListener('openMarket', this);
    this.game.eventsController.addListener('closeMarket', this);

    this.game.eventsController.addListener('createBuilding', this);
  }

  closeMarket = () => {
    this.game.eventsController.runEvent('closeMarket');
  };

  onEscKeyDown = () => {
    this.pauseGame();
  };

  pauseGame = () => {
    this.setInteractive(false);
    if (this.game.advancedLogicPart && !this.game.isMultiplayer) this.game.pause();
  };

  unpauseGame = () => {
    this.setInteractive(true);
    if (this.game.advancedLogicPart && !this.game.isMultiplayer) this.game.resume();
  };

  onOpenMarket = () => {
    this.setInteractive(false);
    this.marketModalVisible = true;
  };

  onCloseMarket = () => {
    this.setInteractive(true);

    this.marketModalVisible = false;
  };

  onEliminate = () => {
    this.pauseGame();

    this.endMessage.showYouLost();
  };

  onWin = () => {
    this.pauseGame();

    this.endMessage.showYouWon();
  };

  onEnd = (message) => {
    this.pauseGame();

    this.endMessage.showCustom(message);
  };

  onMarketButtonClick = (type) => {
    if (this.gameOverModalVisible) return false;

    this.marketModalVisible = true;

    this.game.eventsController.runEvent('openMarket', { type });
  };

  onCloseMarket() {
    this.setInteractive();

    this.marketModalVisible = false;
  }

  createImageSprite(name) {
    const sprite = this.game.texturesManager.createStandardSprite(name);

    this.mapContainer.addChild(sprite);

    return sprite;
  }

  registerSprite(sprite) {
    this.mainContainer.addChild(sprite);
  }

  removeSprite(sprite) {
    this.mainContainer.removeChild(sprite);
  }

  registerMapInterfaceSprite(sprite) {
    this.mapInterfaceContainer.addChild(sprite);
  }

  removeMapInterfaceSprite(sprite) {
    this.mapInterfaceContainer.removeChild(sprite);
  }

  registerMapSprite(sprite) {
    this.mapContainer.addChild(sprite);
  }

  removeMapSprite(sprite) {
    this.mapContainer.removeChild(sprite);
  }

  activate(delta) {
    if (!this.interactive) return false;

    this.moveScreen(delta);
    this.updateZoom(delta);
  }

  moveScreenByPoint(point) {
    this.xViewport += point.x;
    this.yViewport += point.y;

    this.minMaxScreen();

    this.setSpriteContainerPosition();
  }

  minMaxScreen() {
    const minX = this.game.pixiApp.screen.width / 2 - 2 * this.leftOffset() * this.zoom;
    const maxX = this.game.pixiApp.screen.width / 2;
    const minY = this.game.pixiApp.screen.height / 2 - 2 * this.topOffset() * this.zoom;
    const maxY = this.game.pixiApp.screen.height / 2;

    if (this.xViewport < minX) this.xViewport = minX;
    if (this.xViewport > maxX) this.xViewport = maxX;
    if (this.yViewport < minY) this.yViewport = minY;
    if (this.yViewport > maxY) this.yViewport = maxY;
  }

  moveScreen(delta) {
    const positionChange = MOVE_SPEED * this.zoom * delta;

    if (this.movingLeft) this.xViewport += positionChange;
    if (this.movingRight) this.xViewport -= positionChange;
    if (this.movingUp) this.yViewport += positionChange;
    if (this.movingDown) this.yViewport -= positionChange;

    this.minMaxScreen();

    this.setSpriteContainerPosition();
  }

  moveScreenToPoint(point) {
    this.xViewport = this.game.pixiApp.screen.width / 2 - point.x * this.zoom;
    this.yViewport = this.game.pixiApp.screen.height / 2 - point.y * this.zoom;

    this.setSpriteContainerPosition();
  }

  updateZoom(delta) {
    let change = 1;

    if (this.zoomingIn) change *= Math.pow(ZOOMIG_CHANGE, delta);
    if (this.zoomingOut) change *= Math.pow(1 / ZOOMIG_CHANGE, delta);

    this.changeZoom(change);
  }

  globalPointToMapPoint(point) {
    const x = (point.x - this.xViewport) / this.zoom;
    const y = (point.y - this.yViewport) / this.zoom;

    return new Point(x, y);
  }

  onCreateBuilding = (params) => {
    const {
      building: { mapField, owner },
    } = params;

    new BuildingRing({ game: this.game, position: mapField.getPosition().clone(), kingdom: owner });
  };
}
