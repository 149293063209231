import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';

import { BuildingBase } from '../buildings-base.js';

const POWER = 100;

export class PowerPlant extends BuildingBase {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.type = BUILDINGS.POWER_STATION;

    this.onGain(this.owner);
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.POWER_STATION;
  }

  changeOwner(newOwner) {
    this.onLose(this.owner);

    super.changeOwner(newOwner);

    this.onGain(newOwner);
  }

  onGain(kingdom) {
    if (!kingdom) return;
    kingdom.changeEnergyProduction(POWER);
  }

  onLose(kingdom) {
    if (!kingdom) return;
    kingdom.changeEnergyProduction(-POWER);
  }
}
