import { CARDS } from 'common/consts/types/index.js';

import { EnemyKingdom } from '../enemy.js';

export class EscalatorKingdom extends EnemyKingdom {
  constructor(params) {
    super(params);

    this.energyProduction += 10;

    if (this.difficulty >= 2) this.cardsLimit += 1;
    if (this.difficulty >= 3) this.cardsLimit += 2;
  }

  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.SPREAD_SHOT_IMPROVING }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.RANDOM_FORTIFICATION }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.SPREAD_SHOT_IMPROVING }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_MAIN }));

    return cards;
  }
}
