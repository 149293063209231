import { Container, Graphics, Rectangle, Sprite, TilingSprite } from 'pixi.js';

import { CARD_SCALE, REAL_CARD_HEIGHT, REAL_CARD_WIDTH } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { InterfaceBase } from 'client/interface/interface-base.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/index.js';
import { Point } from 'common/data-types/point.js';

const FINAL_CARD_WIDTH = CARD_SCALE * REAL_CARD_WIDTH;
const FINAL_CARD_HEIGHT = CARD_SCALE * REAL_CARD_HEIGHT;

const MARGIN = 75;
const PADDING = 50;
const CONTROLLS_MARGIN = 5;
const GAP_BETWEEN_CARDS = 25;

const BORDER = 5;

const SCROLLBAR_BUTTON_HEIGHT = 26;

const SCROLLBAR_WIDTH = 30;

const SCROLLING_SPEED_BUTTONS = 15;
const SCROLLING_SPEED_WHEEL = 45;

export class CardsBoard extends InterfaceBase {
  constructor(params) {
    super(params);

    this.scrollOffset = 0;
    this.thumbOffset = 0;

    this.visible = false;

    this.interactive = false;
    this.interactiveBlockades = 1;

    this.scrollingUp = false;
    this.scrollingDown = false;
    this.movingThumb = false;
    this.thumbHookY = 0;
    this.thumbOldOffset = 0;

    this.maxColumns = params.maxColumns || 4;

    this.sprites = {};
    this.layout = {};

    this.cards = params.cards || [];
  }

  getNumberOfCards() {
    return this.cards.length;
  }

  calculateLayout = () => {
    const numberOfCards = this.getNumberOfCards();

    const usableWidth = window.innerWidth - MARGIN * 2 - FINAL_CARD_WIDTH - PADDING * 2;
    const maxPossibleColumns = Math.min(Math.max(1, 1 + Math.floor(usableWidth / (FINAL_CARD_WIDTH + GAP_BETWEEN_CARDS))));
    const numberOfColumns = Math.min(maxPossibleColumns, numberOfCards, this.maxColumns);
    const contentWidth = numberOfColumns * FINAL_CARD_WIDTH + (numberOfColumns - 1) * GAP_BETWEEN_CARDS + PADDING * 2;

    const numberOfRows = Math.ceil(numberOfCards / numberOfColumns);

    const contentHeight = numberOfRows * FINAL_CARD_HEIGHT + (numberOfRows - 1) * GAP_BETWEEN_CARDS + PADDING * 2;

    this.layout.numberOfColumns = numberOfColumns;

    this.layout.boxWidth = contentWidth;
    this.layout.boxHeight = Math.min(contentHeight, window.innerHeight - MARGIN * 2);
    this.layout.contentHeight = contentHeight;

    this.layout.boxLeftMargin = window.innerWidth / 2 - this.layout.boxWidth / 2;
    this.layout.boxTopMargin = window.innerHeight / 2 - this.layout.boxHeight / 2;

    this.layout.isScollPresent = this.layout.contentHeight > this.layout.boxHeight;

    if (!this.layout.isScollPresent) return;

    this.layout.scrollbarTrackHeight = this.layout.boxHeight - 2 * CONTROLLS_MARGIN - 2 * SCROLLBAR_BUTTON_HEIGHT;
    this.layout.contentOverflow = this.layout.contentHeight - this.layout.boxHeight;

    this.layout.scrollbarThumbHeight = Math.max(32, this.layout.scrollbarTrackHeight - this.layout.contentOverflow);
    this.layout.scrollbarTrackVoid = this.layout.scrollbarTrackHeight - this.layout.scrollbarThumbHeight;

    this.limitScrollValue();
    this.limitSliderOffset();
  };

  createSprites() {
    this.createWideSprites();
    this.createBoxSprites();
    this.createCancelOverlay();
  }

  createWideSprites() {
    this.createContainer();
    this.createWideContainer();

    this.createOverlaySprite();
    this.createBackgroundSprite();
  }

  createContainer() {
    this.sprites.container = new Container();
    this.sprites.container.zIndex = Z_INDEX.CARDS_BOARD;
    this.sprites.container.visible = false;

    this.registerSprite(this.sprites.container);
  }

  createWideContainer() {
    this.sprites.wideContainer = new Container();

    this.sprites.container.addChild(this.sprites.wideContainer);
  }

  createOverlaySprite() {
    this.sprites.overlay = new Graphics();
    this.sprites.overlay.alpha = 0.85;
    this.sprites.overlay.interactive = true;

    this.updateOverlaySprite();

    this.sprites.wideContainer.addChild(this.sprites.overlay);
  }

  createBackgroundSprite() {
    this.sprites.boxBackground = new Graphics();

    this.updateBackground();

    this.sprites.wideContainer.addChild(this.sprites.boxBackground);
  }

  createBoxSprites() {
    this.createBoxContainer();

    this.createCloseButton();
    this.createScrollbarTrack();
    this.createScrollbarTopButton();
    this.createScrollbarBottomButton();
    this.createScrollbarThumb();
  }

  createBoxContainer() {
    this.sprites.boxContainer = new Container();

    this.updateBoxContainerMask();

    this.sprites.container.addChild(this.sprites.boxContainer);
  }

  updateBoxContainerMask() {
    this.sprites.boxContainer.mask = new Graphics()
      .rect(this.layout.boxLeftMargin, this.layout.boxTopMargin, this.layout.boxWidth, this.layout.boxHeight)
      .fill(0xffffff);
  }

  updateOverlaySprite() {
    this.sprites.overlay.clear().rect(0, 0, window.innerWidth, window.innerHeight).fill('black');
  }

  createCancelOverlay() {
    this.sprites.cancelOverlay = new Sprite();
    this.sprites.cancelOverlay.interactive = true;
    this.sprites.cancelOverlay.cursor = 'pointer';
    this.sprites.cancelOverlay.visible = false;
    this.sprites.cancelOverlay.zIndex = Z_INDEX.CANCEL_OVERLAY;

    this.sprites.cancelOverlay.addEventListener('mouseup', withLeftClick(this.onCancelOverlayMouseUp));
    this.sprites.cancelOverlay.addEventListener('mouseout', this.onCancelOverlayMouseOut);

    this.updateCancelOverlay();

    this.registerSprite(this.sprites.cancelOverlay);
  }

  updateCancelOverlay() {
    this.sprites.cancelOverlay.hitArea = new Rectangle(0, 0, window.innerWidth, window.innerHeight);
  }

  updateBackground() {
    this.sprites.boxBackground
      .clear()
      .rect(
        this.layout.boxLeftMargin - BORDER,
        this.layout.boxTopMargin - BORDER,
        this.layout.boxWidth + 2 * BORDER,
        this.layout.boxHeight + 2 * BORDER
      )
      .fill(0xaaaaaa, 0.2)
      .stroke({ width: BORDER, color: 'black' });
  }

  createCloseButton() {
    this.sprites.closeButtom = this.game.texturesManager.createSprite(IMAGES.INTERFACE.CLOSE_BUTTON);
    this.sprites.closeButtom.anchor.set(0, 0);
    this.sprites.closeButtom.interactive = true;
    this.sprites.closeButtom.cursor = 'pointer';

    this.sprites.closeButtom.addEventListener('click', withLeftClick(this.onCloseClick));

    this.updateCloseButton();

    this.sprites.boxContainer.addChild(this.sprites.closeButtom);
  }

  updateCloseButton() {
    this.sprites.closeButtom.x = this.layout.boxLeftMargin + CONTROLLS_MARGIN;
    this.sprites.closeButtom.y = this.layout.boxTopMargin + CONTROLLS_MARGIN;
  }

  onCancelOverlayMouseOut = () => {
    this.cancelActions();
  };

  onCancelOverlayMouseUp = () => {
    this.cancelActions();
  };

  onCloseClick = () => {
    this.game.eventsController.runEvent('closeMarket');
  };

  createScrollbarTrack() {
    this.sprites.scrollbarTrack = new Graphics();

    this.updateScrollbarTrack();

    this.sprites.boxContainer.addChild(this.sprites.scrollbarTrack);
  }

  updateScrollbarTrack() {
    this.sprites.scrollbarTrack.visible = this.layout.isScollPresent;
    this.sprites.scrollbarTrack
      .clear()
      .rect(
        this.layout.boxLeftMargin + this.layout.boxWidth - SCROLLBAR_WIDTH - CONTROLLS_MARGIN,
        this.layout.boxTopMargin + CONTROLLS_MARGIN + SCROLLBAR_BUTTON_HEIGHT,
        SCROLLBAR_WIDTH,
        this.layout.boxHeight - CONTROLLS_MARGIN * 2 - SCROLLBAR_BUTTON_HEIGHT * 2
      )
      .fill('black');
  }

  createScrollbarTopButton() {
    this.sprites.scrollbarTopButton = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SCROLLBAR.TOP_BUTTON);
    this.sprites.scrollbarTopButton.anchor.set(1, 0);

    this.sprites.scrollbarTopButton.interactive = true;
    this.sprites.scrollbarTopButton.cursor = 'pointer';
    this.sprites.scrollbarTopButton.addEventListener('mousedown', withLeftClick(this.onButtonUpDown));

    this.updateScrollbarTopButtonPosition();

    this.sprites.boxContainer.addChild(this.sprites.scrollbarTopButton);
  }

  updateScrollbarTopButtonPosition() {
    this.sprites.scrollbarTopButton.visible = this.layout.isScollPresent;

    this.sprites.scrollbarTopButton.x = this.layout.boxLeftMargin + this.layout.boxWidth - CONTROLLS_MARGIN;
    this.sprites.scrollbarTopButton.y = this.layout.boxTopMargin + CONTROLLS_MARGIN;
  }

  createScrollbarBottomButton() {
    this.sprites.scrollbarBottomButton = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SCROLLBAR.BOTTOM_BUTTON);
    this.sprites.scrollbarBottomButton.anchor.set(1, 1);

    this.sprites.scrollbarBottomButton.interactive = true;
    this.sprites.scrollbarBottomButton.cursor = 'pointer';
    this.sprites.scrollbarBottomButton.addEventListener('mousedown', withLeftClick(this.onButtonDownDown));

    this.updateScrollbarBottomButtonPosition();

    this.sprites.boxContainer.addChild(this.sprites.scrollbarBottomButton);
  }

  updateScrollbarBottomButtonPosition() {
    this.sprites.scrollbarBottomButton.visible = this.layout.isScollPresent;

    this.sprites.scrollbarBottomButton.x = this.layout.boxLeftMargin + this.layout.boxWidth - CONTROLLS_MARGIN;
    this.sprites.scrollbarBottomButton.y = this.layout.boxTopMargin + this.layout.boxHeight - CONTROLLS_MARGIN;
  }

  createScrollbarThumb() {
    this.sprites.scrollbarThumbContainer = new Container();
    this.sprites.scrollbarThumbContainer.sortableChildren = true;

    this.sprites.scrollbarThumbCenter = new TilingSprite({
      texture: this.game.texturesManager.getTexture(IMAGES.INTERFACE.SCROLLBAR.THUMB_CENTER),
      width: SCROLLBAR_WIDTH,
      height: this.layout.scrollbarThumbHeight,
    });
    this.sprites.scrollbarThumbCenter.anchor.set(1, 0);

    this.sprites.scrollbarThumbTop = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SCROLLBAR.THUMB_TOP);
    this.sprites.scrollbarThumbTop.anchor.set(1, 0);
    this.sprites.scrollbarThumbTop.zIndex = 1;

    this.sprites.scrollbarThumbBottom = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SCROLLBAR.THUMB_BOTTOM);
    this.sprites.scrollbarThumbBottom.anchor.set(1, 1);
    this.sprites.scrollbarThumbBottom.zIndex = 1;

    this.sprites.scrollbarThumbHandler = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SCROLLBAR.THUMB_HANDLER);
    this.sprites.scrollbarThumbHandler.anchor.set(1, 0.5);
    this.sprites.scrollbarThumbHandler.zIndex = 1;

    this.updateScrollbarThumb();

    this.sprites.scrollbarThumbContainer.addChild(this.sprites.scrollbarThumbTop);
    this.sprites.scrollbarThumbContainer.addChild(this.sprites.scrollbarThumbCenter);
    this.sprites.scrollbarThumbContainer.addChild(this.sprites.scrollbarThumbBottom);
    this.sprites.scrollbarThumbContainer.addChild(this.sprites.scrollbarThumbHandler);

    this.sprites.scrollbarThumbContainer.interactive = true;
    this.sprites.scrollbarThumbContainer.cursor = 'pointer';
    this.sprites.scrollbarThumbContainer.addEventListener('mousedown', withLeftClick(this.onThumbDown));

    this.sprites.boxContainer.addChild(this.sprites.scrollbarThumbContainer);
  }

  updateScrollbarThumb = () => {
    this.sprites.scrollbarThumbContainer.visible = this.layout.isScollPresent;

    this.sprites.scrollbarThumbContainer.x = this.layout.boxLeftMargin + this.layout.boxWidth - CONTROLLS_MARGIN;
    this.sprites.scrollbarThumbContainer.y = this.layout.boxTopMargin + SCROLLBAR_BUTTON_HEIGHT + CONTROLLS_MARGIN + this.thumbOffset;

    this.sprites.scrollbarThumbCenter.height = this.layout.scrollbarThumbHeight;

    this.sprites.scrollbarThumbBottom.y = this.layout.scrollbarThumbHeight;

    this.sprites.scrollbarThumbHandler.y = this.layout.scrollbarThumbHeight / 2;
  };

  setInteractive = (value) => {
    if (value === this.interactive) return;

    this.interactive = value;

    this.cards.forEach((card) => {
      card.setInteractive(value);
    });
  };

  setVisibility = (value) => {
    if (value === this.visible) return false;

    this.visible = value;

    this.sprites.container.visible = value;

    this.cancelActions();

    if (value) {
      this.game.eventsController.addListener('mouseWheelUp', this);
      this.game.eventsController.addListener('mouseWheelDown', this);
    } else {
      this.game.eventsController.removeListener('mouseWheelUp', this);
      this.game.eventsController.removeListener('mouseWheelDown', this);
    }
  };

  isMoving = () => {
    return this.movingThumb || this.scrollingUp || this.scrollingDown;
  };

  onMouseWheelUp = () => {
    if (this.isMoving()) return true;

    this.changeScroll(-SCROLLING_SPEED_WHEEL);
    return true;
  };

  onMouseWheelDown = () => {
    if (this.isMoving()) return true;

    this.changeScroll(SCROLLING_SPEED_WHEEL);
    return true;
  };

  onButtonUpDown = () => {
    this.sprites.cancelOverlay.visible = true;
    this.scrollingUp = true;
  };

  onButtonDownDown = () => {
    this.sprites.cancelOverlay.visible = true;
    this.scrollingDown = true;
  };

  onThumbDown = (event) => {
    const point = event.data.global;

    this.thumbHookY = point.y;
    this.thumbOldOffset = this.thumbOffset;

    this.sprites.cancelOverlay.visible = true;

    this.movingThumb = true;

    this.game.eventsController.addListener('mouseMove', this);
  };

  onMouseMove = (params) => {
    const { position } = params;

    this.setThumbOffset(position.y - this.thumbHookY + this.thumbOldOffset);

    this.updateScrollbarThumb();
    this.setCardsPositions();
  };

  cancelActions = () => {
    this.sprites.cancelOverlay.visible = false;

    this.scrollingUp = false;
    this.scrollingDown = false;

    if (this.movingThumb) {
      this.movingThumb = false;
      this.game.eventsController.removeListener('mouseMove', this);
    }
  };

  changeScroll = (value = 0) => {
    this.updateScrollOffset(value);

    this.updateScrollbarThumb();
    this.setCardsPositions();
  };

  recalculateScrollOffset = () => {
    this.updateScrollOffset();
  };

  updateScrollOffset = (value = 0) => {
    this.scrollOffset += value;
    this.limitScrollValue();

    this.thumbOffset = this.layout.scrollbarTrackVoid * (this.scrollOffset / this.layout.contentOverflow);
    this.limitSliderOffset();
  };

  setThumbOffset = (value = 0) => {
    this.thumbOffset = value;
    this.limitSliderOffset();

    this.scrollOffset = (this.thumbOffset / this.layout.scrollbarTrackVoid) * this.layout.contentOverflow;
    this.limitScrollValue();
  };

  limitScrollValue = () => {
    const maxOffset = this.layout.contentHeight - this.layout.boxHeight;

    if (this.scrollOffset < 0) this.scrollOffset = 0;
    if (this.scrollOffset > maxOffset) this.scrollOffset = maxOffset;
  };

  limitSliderOffset = () => {
    if (this.thumbOffset < 0) this.thumbOffset = 0;
    if (this.thumbOffset > this.layout.scrollbarTrackVoid) this.thumbOffset = this.layout.scrollbarTrackVoid;
  };

  setCardsPositions() {
    let topOffset = this.layout.boxTopMargin + PADDING + FINAL_CARD_HEIGHT / 2;
    let leftOffset = this.layout.boxLeftMargin + PADDING + FINAL_CARD_WIDTH / 2;

    this.cards.forEach((card, index) => {
      card.setPosition(new Point(leftOffset, topOffset - this.scrollOffset));

      if ((index + 1) % this.layout.numberOfColumns === 0) {
        topOffset += FINAL_CARD_HEIGHT + GAP_BETWEEN_CARDS;
        leftOffset = this.layout.boxLeftMargin + PADDING + FINAL_CARD_WIDTH / 2;
      } else {
        leftOffset += FINAL_CARD_WIDTH + GAP_BETWEEN_CARDS;
      }
    });
  }

  onCardClick() {}

  afterResize() {
    this.calculateLayout();
    this.updateInterface();
  }

  updateInterface = () => {
    this.setCardsPositions();

    this.updateBoxContainerMask();
    this.updateCancelOverlay();

    this.updateOverlaySprite();
    this.updateBackground();
    this.updateCloseButton();
    this.updateScrollbarTrack();
    this.updateScrollbarTopButtonPosition();
    this.updateScrollbarBottomButtonPosition();
    this.updateScrollbarThumb();
  };

  activate(delta) {
    if (this.scrollingUp) this.changeScroll(-SCROLLING_SPEED_BUTTONS * delta);
    if (this.scrollingDown) this.changeScroll(SCROLLING_SPEED_BUTTONS * delta);
  }
}
