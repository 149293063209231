import { COLORS } from 'common/consts/colors.js';
import { CARDS, EFFECTS, IMAGES } from 'common/consts/types/index.js';

import { TOOLTIP } from '../consts/tooltip.js';

export const RANDOM = {
  [CARDS.FORTIFICATE_AROUND_MAIN]: {
    type: CARDS.FORTIFICATE_AROUND_MAIN,
    cost: 300,
    lightweight: true,
    title: 'Free fortifications',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_AROUND_MAIN,
    description: 'Lightweight.\nAdd FORTIFICATION fortification to all fields adjacent to the main building.',
    effects: [EFFECTS.FORTIFICATE_AROUND_MAIN],
    stats: {
      fortification: 1,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.LIGHTWEIGHT, TOOLTIP.TYPE.FORTIFICATION],
  },
  /* [CARDS.PLACE_MINE]: {
    type: CARDS.PLACE_MINE,
    energyCost: 50,
    scienceCost: 100,
    title: 'Mine',
    image: IMAGES.CARDS.IMAGES.PLACE_MINE,
    description: 'Place a mine on a an own empty tile.',
    effects: [EFFECTS.PLACE_MINE],
    color: COLORS.CARD.BLUE,
  },*/
  [CARDS.PLACE_MINES_RANDOMLY]: {
    type: CARDS.PLACE_MINES_RANDOMLY,
    cost: 500,
    title: 'Random minefield',
    image: IMAGES.CARDS.IMAGES.PLACE_MINES_RANDOMLY,
    description: 'Place NUMBER_OF_MINES mines on random, own empty tiles.',
    effects: [EFFECTS.PLACE_MINES_RANDOMLY],
    color: COLORS.CARD.BLUE,
    stats: {
      numberOfMines: 3,
    },
    tooltipTypes: [TOOLTIP.TYPE.MINE],
  },
  [CARDS.PLACE_RANDOM_CANNON_RANDOMLY]: {
    type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY,
    cost: 1000,
    title: 'Random random cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_RANDOM_CANNON_RANDOMLY,
    description: 'Place 2 random cannons on random, own empty tiles.',
    effects: [EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY],
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.RANDOM_CANNON],
  } /*
  [CARDS.PLACE_WAREHOUSE]: {
    type: CARDS.PLACE_WAREHOUSE,
    scienceCost: 50,
    title: 'Warehouse',
    image: IMAGES.CARDS.IMAGES.PLACE_WAREHOUSE,
    description: 'Destroy.\nPlace a warehouse on an own empty tile. Gain ENERGY energy.',
    effects: [EFFECTS.PLACE_WAREHOUSE, EFFECTS.DESTROY, EFFECTS.ADD_ENERGY],
    stats: {
      energy: 300,
    },
    color: COLORS.CARD.GREEN,
  },*/,

  [CARDS.SINGLE_FIRE]: {
    type: CARDS.SINGLE_FIRE,
    cost: 100,
    title: 'Single fire',
    image: IMAGES.CARDS.IMAGES.SINGLE_FIRE,
    description: 'Fire a shot with power POWER and piercing PIERCING.',
    effects: [EFFECTS.SHOT],
    stats: {
      power: 25,
      piercing: 25,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.POWER, TOOLTIP.TYPE.PIERCING],
  },
  /*[CARDS.POISON_TO_ALL]: {
    type: CARDS.POISON_TO_ALL,
    energyCost: 100,
    scienceCost: 100,
    title: 'Poisoning',
    image: IMAGES.CARDS.IMAGES.POISON_TO_ALL,
    description: 'Add POISON poison to all players.',
    effects: [EFFECTS.POISON_TO_ALL],
    stats: {
      poison: 2,
    },
    color: COLORS.CARD.RED,
  },*/
  [CARDS.DESTROY_BUILDING]: {
    type: CARDS.DESTROY_BUILDING,
    cost: 300,
    title: 'Demolition',
    image: IMAGES.CARDS.IMAGES.DESTROY_BUILDING,
    description: 'Destroy an own building to gain ENERGY energy.',
    effects: [EFFECTS.DESTROY_OWN_BUILDING, EFFECTS.ADD_ENERGY],
    stats: {
      energy: 300,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.VOID_TO_ALL]: {
    type: CARDS.VOID_TO_ALL,
    cost: 500,
    title: 'Justice till the end',
    image: IMAGES.CARDS.IMAGES.VOID_TO_ALL,
    description: 'Lightweight.\nAdd VOID_POWER void to all players.',
    lightweight: true,
    effects: [EFFECTS.VOID_TO_ALL],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
    tooltipTypes: [TOOLTIP.TYPE.VOID],
  } /*
  [CARDS.REDUCE_ENERGY_COST_RANDOMLY]: {
    type: CARDS.REDUCE_ENERGY_COST_RANDOMLY,
    scienceCost: 200,
    energyCost: 200,
    title: 'Discount',
    image: IMAGES.CARDS.IMAGES.REDUCE_ENERGY_COST_RANDOMLY,
    description: 'Reduce energy cost of a random card in the deck by ENERGY.',
    effects: [EFFECTS.REDUCE_ENERGY_COST_RANDOMLY],
    color: COLORS.CARD.GREEN,
    stats: {
      energy: 50,
    },
  },*/,
  [CARDS.PLACE_DEFENDER]: {
    type: CARDS.PLACE_DEFENDER,
    cost: 300,
    title: 'Defender',
    image: IMAGES.CARDS.IMAGES.PLACE_DEFENDER,
    description: 'Drain DRAIN.\nPlace a defender on own empty tile.',
    effects: [EFFECTS.PLACE_DEFENDER],
    color: COLORS.CARD.BLUE,
    stats: {
      drain: 200,
    },
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.DEFENDER],
  },
  /*
  [CARDS.INCREASE_ENERGY_PRODUCTION]: {
    type: CARDS.INCREASE_ENERGY_PRODUCTION,
    energyCost: 200,
    scienceCost: 10,
    title: 'Safe energy.',
    image: IMAGES.CARDS.IMAGES.INCREASE_ENERGY_PRODUCTION,
    description: 'Increase energy production by ENERGY_PRODUCTION.\nUsage left: USAGE.',
    effects: [EFFECTS.INCREASE_ENERGY_PRODUCTION, EFFECTS.LIMITED_USAGE],
    stats: {
      energyProduction: 20,
      usage: 5,
    },
    color: COLORS.CARD.GREEN,
  },*/
  [CARDS.SPREAD_SHOT_IMPROVING]: {
    type: CARDS.SPREAD_SHOT_IMPROVING,
    cost: 900,
    title: 'Suppressive fire',
    image: IMAGES.CARDS.IMAGES.SPREAD_SHOT_IMPROVING,
    description: 'Fire a shot with spread SPREAD. Increase the spreed of this card by SPREAD_INCREASE.',
    effects: [EFFECTS.SPREAD_SHOT, EFFECTS.INCREASE_SPREAD],
    stats: {
      spread: 10,
      spreadIncrease: 3,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },

  [CARDS.DRAW_CARDS]: {
    type: CARDS.DRAW_CARDS,
    cost: 500,
    title: 'Draw cards',
    image: IMAGES.CARDS.IMAGES.DRAW_CARDS,
    description: 'Draw CARDS cards.',
    effects: [EFFECTS.DRAW_CARDS],
    stats: {
      cards: 2,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.TRASH_CARD]: {
    type: CARDS.TRASH_CARD,
    cost: 500,
    title: 'Slimming',
    image: IMAGES.CARDS.IMAGES.TRASH_CARD,
    description: 'Destroy.\nTrash a card from your hand.',
    effects: [EFFECTS.TRASH_CARD, EFFECTS.DESTROY],
    color: COLORS.CARD.GREEN,
  },
  [CARDS.FAIR_SHOT]: {
    type: CARDS.FAIR_SHOT,
    cost: 700,
    title: 'Fair shot',
    image: IMAGES.CARDS.IMAGES.FAIR_SHOT,
    description: 'Fire SHOTS sniper shots at every enemy base.',
    effects: [EFFECTS.FAIR_SHOT],
    stats: {
      shots: 12,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.FORTIFICATE_BORDERS]: {
    type: CARDS.FORTIFICATE_BORDERS,
    cost: 700,
    title: 'Isolation',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_BORDERS,
    description: 'Add FORTIFICATION fortifications to all border fields.',
    effects: [EFFECTS.FORTIFICATE_BORDERS],
    stats: {
      fortification: 1,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.FORTIFICATE_BUILDINGS]: {
    type: CARDS.FORTIFICATE_BUILDINGS,
    cost: 500,
    title: 'Trenches',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_BUILDINGS,
    description: 'Add FORTIFICATION fortifications to all own fields with buildings.',
    effects: [EFFECTS.FORTIFICATE_BUILDINGS],
    stats: {
      fortification: 2,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.POWER_SHOT]: {
    type: CARDS.POWER_SHOT,
    cost: 300,
    title: 'Bulldozer',
    image: IMAGES.CARDS.IMAGES.POWER_SHOT,
    description: 'Fire a sniper shot with power POWER and piercing PIERCING.',
    effects: [EFFECTS.SHOT],
    stats: {
      shots: 1,
      inaccuracy: 0,
      piercing: 2,
      power: 50,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.POWER, TOOLTIP.TYPE.PIERCING, TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.ENERGY_TO_BULLETS]: {
    type: CARDS.ENERGY_TO_BULLETS,
    cost: 100,
    title: 'Blast',
    image: IMAGES.CARDS.IMAGES.ENERGY_TO_BULLETS,
    description: 'Spend all energy, fire a shot with spread SPREAD for every energy spent.',
    effects: [EFFECTS.ENERGY_TO_BULLETS],
    stats: {
      spread: 15,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },

  [CARDS.PLACE_SHORT_RANGE_CANNON]: {
    type: CARDS.PLACE_SHORT_RANGE_CANNON,
    cost: 100,
    title: 'Short-range cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_SHORT_RANGE_CANNON,
    description: 'Place a short-range cannon on an own empty tile.',
    effects: [EFFECTS.PLACE_SHORT_RANGE_CANNON],
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SHORT_RANGE_CANNON],
  },
  [CARDS.FORTIFICATE_MAIN]: {
    type: CARDS.FORTIFICATE_MAIN,
    cost: 100,
    title: 'Defence packet',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_MAIN,
    description: "Destroy.\nAdd FORTIFICATION fortifications to the main building's field and all own adjacent fields.",
    effects: [EFFECTS.DESTROY, EFFECTS.FORTIFICATE_MAIN],
    stats: {
      fortification: 10,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.INCREASE_CARD_LIMIT]: {
    type: CARDS.INCREASE_CARD_LIMIT,
    cost: 1200,
    title: 'Overwhelming',
    image: IMAGES.CARDS.IMAGES.INCREASE_CARD_LIMIT,
    description: 'Destroy.\nIncrease your cards limit by 1.',
    effects: [EFFECTS.DESTROY, EFFECTS.INCREASE_CARD_LIMIT],
    color: COLORS.CARD.GREEN,
  },
  [CARDS.SNIPER_SHOT_IMPROVING]: {
    type: CARDS.SNIPER_SHOT_IMPROVING,
    cost: 1200,
    title: 'Slow finisher',
    image: IMAGES.CARDS.IMAGES.SNIPER_SHOT_IMPROVING,
    description: 'Fire SHOTS sniper shots. Increase the number of shots of this card by SHOTS_INCREASE.',
    effects: [EFFECTS.SNIPER_FIRE, EFFECTS.INCREASE_SHOTS],
    stats: {
      shots: 8,
      shotsIncrease: 2,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
};
