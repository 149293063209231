import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BUILDINGS, ENTITIES, IMAGES } from 'common/consts/types/index.js';
import { degreesToRadians, perFrameSpeed, perFrameTimer, rotationToSpeeds, uniqueString } from 'common/helpers/index.js';

import { BasicCannon } from './cannons/basic.js';

const FIRE = uniqueString();

export class MainBuilding extends BasicCannon {
  constructor(params) {
    super(params);

    this.tasks = [];

    this.range = HEXAGON_SIZE * 33;
    this.rotate = false;

    this.demolitionable = false;

    this.type = BUILDINGS.MAIN_BUILDING;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.MAIN_BUILDING;
  }

  activate(delta) {
    super.activate(delta);

    this.doTasks();
  }

  doTasks() {
    this.tasks.forEach((task) => {
      if (task.type === FIRE) {
        this.fireBulletsTaskDo(task);
      }
    });

    this.tasks = this.tasks.filter((task) => !task.done);
  }

  multishot(shots = 1, params = {}) {
    const { target = this.owner.target } = params;

    const rotationToTarget = target.clone().subtract(this.position).getRotation();

    const angleDiff = degreesToRadians(this.fireInaccuracy / shots);
    let angle = rotationToTarget + angleDiff / 2 - degreesToRadians(this.fireInaccuracy / 2);

    for (let i = 0; i < shots; i++) {
      this.game.entitiesController.create(ENTITIES.BULLET, {
        x: this.position.x,
        y: this.position.y,
        ...rotationToSpeeds(angle, perFrameSpeed(this.bulletSpeed)),
        lifeTime: perFrameTimer(this.range / this.bulletSpeed),
        owner: this.owner,
        ...params,
      });

      angle += angleDiff;
    }
  }

  fireBulletsTask(params = {}) {
    this.tasks.push({ ...params, type: FIRE, period: 15, timer: 0 });
  }

  fireBulletsTaskDo(task) {
    const { timer, period } = task;

    task.timer--;
    if (timer >= 0) return false;

    this.fireBullets(task);

    task.timer = period;
    task.shots--;

    if (task.shots < 1) task.done = true;
  }

  onDamage() {
    this.mapField.removeBuildingRef();
    this.owner.onDestroyMainBuilding();

    this.destroy();
  }
}
