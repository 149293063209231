import { BUILDINGS, IMAGES } from 'common/consts/types/index.js';
import { BuildingBase } from '../buildings-base.js';

export class Warehouse extends BuildingBase {
  constructor(params) {
    super(params);

    this.type = BUILDINGS.WAREHOUSE;
  }

  getSpriteName() {
    return IMAGES.BUILDINGS.WAREHOUSE;
  }
}
