import { COLORS } from 'common/consts/colors.js';
import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARDS, EFFECTS, IMAGES } from 'common/consts/types/index.js';

export const STARTING = {
  [CARDS.SPARK]: {
    type: CARDS.SPARK,
    title: 'A Spark',
    image: IMAGES.CARDS.IMAGES.SPARK,
    description: 'Gain ENERGY energy.',
    effects: [EFFECTS.ADD_ENERGY],
    stats: {
      energy: 100,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.SPREAD_SHOT]: {
    type: CARDS.SPREAD_SHOT,
    title: 'Spread Shot',
    image: IMAGES.CARDS.IMAGES.SPREAD_SHOT,
    description: 'Fire a shot with spread SPREAD.',
    effects: [EFFECTS.SPREAD_SHOT],
    stats: {
      spread: 10,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },
  [CARDS.SNIPER_FIRE]: {
    type: CARDS.SNIPER_FIRE,
    title: 'Sniper fire',
    image: IMAGES.CARDS.IMAGES.SNIPER_FIRE,
    description: 'Fire SHOTS sniper shots.',
    effects: [EFFECTS.SNIPER_FIRE],
    stats: {
      shots: 5,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.FORTIFICATION]: {
    type: CARDS.FORTIFICATION,
    title: 'Instant Wall',
    image: IMAGES.CARDS.IMAGES.FORTIFICATION,
    description: 'Add FORTIFICATION fortifications to an own field.',
    effects: [EFFECTS.FORTIFICATION],
    stats: {
      fortification: 7,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.RANDOM_FORTIFICATION]: {
    type: CARDS.RANDOM_FORTIFICATION,
    title: 'Fortificate everything',
    image: IMAGES.CARDS.IMAGES.RANDOM_FORTIFICATION,
    description: 'Add FORTIFICATION fortifications to up to FIELDS random own, empty fields.',
    effects: [EFFECTS.RANDOM_FORTIFICATION],
    stats: {
      fortification: 2,
      fields: 6,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
};
