import { uniqueString } from 'common/helpers/data.js';

export const EFFECTS = {
  DRAIN: uniqueString(),

  PLACE_BASIC_CANNON: uniqueString(),
  PLACE_SHORT_RANGE_CANNON: uniqueString(),
  PLACE_RANDOM_CANNON_RANDOMLY: uniqueString(),
  PLACE_SNIPER_CANNON: uniqueString(),
  PLACE_DEFENDER: uniqueString(),

  PLACE_WAREHOUSE: uniqueString(),
  PLACE_POWER_STATION: uniqueString(),
  PLACE_FORGE: uniqueString(),

  PLACE_MINES_RANDOMLY: uniqueString(),
  PLACE_MINE: uniqueString(),

  DESTROY_OWN_BUILDING: uniqueString(),

  ADD_ENERGY: uniqueString(),
  INCREASE_ENERGY_PRODUCTION: uniqueString(),
  REDUCE_ENERGY_COST_RANDOMLY: uniqueString(),

  TRASH_CARD: uniqueString(),

  DRAW_CARDS: uniqueString(),

  INCREASE_POWER: uniqueString(),
  INCREASE_MULTISHOT: uniqueString(),

  SINGLE_FIRE: uniqueString(),
  SNIPER_FIRE: uniqueString(),
  MULTISHOT: uniqueString(),
  ENERGY_TO_BULLETS: uniqueString(),
  FAIR_SHOT: uniqueString(),

  POISON_TO_ALL: uniqueString(),
  VOID_TO_ALL: uniqueString(),
  VOID_TO_OTHERS: uniqueString(),

  RANDOM_FORTIFICATION: uniqueString(),
  FORTIFICATION: uniqueString(),
  FORTIFICATE_BORDERS: uniqueString(),
  FORTIFICATE_MAIN: uniqueString(),
  FORTIFICATE_AROUND_MAIN: uniqueString(),
  FORTIFICATE_BUILDINGS: uniqueString(),

  INCREASE_CARD_LIMIT: uniqueString(),

  // common card effects

  REFILL: uniqueString(),
  DESTROY: uniqueString(),

  SPREAD_SHOT: uniqueString(),
  INCREASE_SPREAD: uniqueString(),
  INCREASE_SHOTS: uniqueString(),

  SHOT: uniqueString(),

  LIMITED_USAGE: uniqueString(),

  // player effects

  POISON: uniqueString(),
  VOID: uniqueString(),
};
